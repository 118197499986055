// ** Redux Imports
import { Action, AnyAction, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux';
import rootReducer from './rootReducer'

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: false
        })
    }
})

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<RT = void> = ThunkAction<Promise<RT>, RootState, unknown, AnyAction>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export { store }