import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customAxiosApi, globalConfig } from "src/config/axios-retry";
import Toast from "src/shared/toast/Toast";
import { ChatInitialState } from "src/utils/interfaces/chat/Chat.store";
import { ShowChatWithPrefilledTextInterface } from "src/utils/interfaces/chat/ShowChatWithPrefilledTextInterface";

const appKey = 'appAgoraChat';
const apiModuleUrl = 'pa/ft/chat';
const createChatWithApplicantUrl = apiModuleUrl + '/create-applicant-chat';
const sendChatMessageurl = apiModuleUrl + '/send-chat-msg';
const getChatsUrl = apiModuleUrl + '/get-chats';
const getChatMessageUrl = apiModuleUrl + '/get-chat-messages';
const getChatMessageUploadAPIUrl = apiModuleUrl + '/get-chat-message-upload-url';
const confirmChatMessageFileUploadAPIUrl = apiModuleUrl + '/confirm-chat-message-upload-complete';
const initiateVoiceCallApiUrl = apiModuleUrl + '/initiate-call';

export const createChatWithApplicant = createAsyncThunk(`${appKey}/createChatWithApplicant`, async (body: { applicationId: string }) => {
    const response = await customAxiosApi.post(createChatWithApplicantUrl, { ...body }, globalConfig);
    return response.data.data;
})

export const getChats = createAsyncThunk(`${appKey}/getChats`, async (query: { skip: number, limit: number }) => {
    const response = await customAxiosApi.get(`${getChatsUrl}?skip=${query.skip}&limit=${query.limit}`, globalConfig);
    return response.data.data;
})

export const pollChats = createAsyncThunk(`${appKey}/pollChats`, async (query: { skip: number, limit: number }) => {
    const response = await customAxiosApi.get(`${getChatsUrl}?skip=${query.skip}&limit=${query.limit}`, globalConfig);
    return response.data.data;
})

export const getChatMessages = createAsyncThunk(`${appKey}/getChatMessages`, async (query: { chatId: string, fromId: number }) => {
    const response = await customAxiosApi.get(`${getChatMessageUrl}?chatId=${query.chatId}&fromId=${query.fromId}&getNewer=true`, globalConfig);
    return response.data.data;
})

export const getChatMessagesUploadUrl = createAsyncThunk(`${appKey}/getChatMessagesUploadUrl`, async (query: { chatId: string, msgId: number }) => {
    const response = await customAxiosApi.get(`${getChatMessageUploadAPIUrl}?chatId=${query.chatId}&msgId=${query.msgId}`, globalConfig);
    return response.data.data;
})

export const confirmChatMessageFileUpload = createAsyncThunk(`${appKey}/confirmChatMessageFileUpload`, async (query: { chatId: string, msgId: number }) => {
    const response = await customAxiosApi.get(`${confirmChatMessageFileUploadAPIUrl}?chatId=${query.chatId}&msgId=${query.msgId}`, globalConfig);
    return response.data.data;
})

export const sendChatMessage = createAsyncThunk(`${appKey}/sendChatMessage`, async (body: any, { dispatch }) => {
    const response = await customAxiosApi.post(sendChatMessageurl, { ...body }, globalConfig);
    return response.data.data;
})

export const initiateVoiceCall = createAsyncThunk(`${appKey}/initiateVoiceCall`, async (body: any, { dispatch }) => {
    const response = await customAxiosApi.post(initiateVoiceCallApiUrl, { ...body }, globalConfig);
    return response.data.data;
})

export const setSignalingEngine = createAction<any>(`${appKey}/setSignalingEngine`);
export const setRtmToken = createAction<string>(`${appKey}/setRtmToken`);
export const setMobileChatViewVisibility = createAction<boolean>(`${appKey}/setMobileChatViewVisibility`);
export const setShowChatWithPrefilledText = createAction<ShowChatWithPrefilledTextInterface>(`${appKey}/setShowChatWithPrefilledText`);

const initialState: ChatInitialState = {
    isLoadingList: true,
    chatList: { conversations: [], count: 0, rtmToken: '', unReadMessagesCount: null },
    signalingEngine: null,
    rtmToken: null,
    showMobileChatViewVisibility: false,
    showChatWithPrefilledText: null
};

export const appChatSlice = createSlice({
    name: appKey,
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createChatWithApplicant.fulfilled, (state, action) => { })
        builder.addCase(sendChatMessage.fulfilled, (state, action) => { })
        builder.addCase(sendChatMessage.rejected, (state, action) => {
            Toast.Error({ content: action.error?.message })
        })
        builder.addCase(getChats.pending, (state, action) => {
            state.isLoadingList = true
        })
        builder.addCase(getChats.fulfilled, (state, action) => {
            state.isLoadingList = false;
            state.chatList = action?.payload;
        });
        builder.addCase(pollChats.fulfilled, (state, action) => {
            state.chatList = action?.payload;
        });
        builder.addCase(getChatMessages.fulfilled, (state, action) => { })
        builder.addCase(getChatMessagesUploadUrl.fulfilled, (state, action) => { })
        builder.addCase(confirmChatMessageFileUpload.fulfilled, (state, action) => { })
        builder.addCase(setSignalingEngine, (state, action) => {
            state.signalingEngine = action.payload;
        })
        builder.addCase(setRtmToken, (state, action) => {
            state.rtmToken = action.payload;
        })
        builder.addCase(setMobileChatViewVisibility, (state, action) => {
            state.showMobileChatViewVisibility = action.payload;
        })
        builder.addCase(setShowChatWithPrefilledText, (state, action) => {
            state.showChatWithPrefilledText = action.payload;
        })
    }
})

export default appChatSlice.reducer
