import { initialDirection } from "../../redux/layout/layout";

export const Languages = [
    {
        id: 'en',
        name: 'English'
    }, {
        id: 'ar',
        name: 'العربية'
    }
]

export const appLanguage = initialDirection() ? "ar" : "en";

export const fulltimePartnerId = localStorage.getItem('fulltime-partner-id');