import { createSlice } from '@reduxjs/toolkit'
import themeConfig from '../../config/themeConfig'

export const initialDirection = () => {
    const item = localStorage.getItem('direction')
    return item ? JSON.parse(item) : themeConfig.layout.isRTL
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        routerTransition: themeConfig.layout.routerTransition,
        isRTL: initialDirection(),
        isFormEditing: false,
        langTogglerClicked: false
    },
    reducers: {
        handleRTL: (state, action) => {
            state.isRTL = action.payload
            window.localStorage.setItem('direction', JSON.stringify(action.payload))
        },
        handleRouterTransition: (state, action) => {
            state.routerTransition = action.payload
        },
        handleFormEditing: (state, action) => {
            state.isFormEditing = action.payload
        },
        handleLangTogglerClicked: (state, action) => {
            state.langTogglerClicked = action.payload
        }
    }
})

export const {
    handleRTL,
    handleRouterTransition,
    handleFormEditing,
    handleLangTogglerClicked
} = layoutSlice.actions

export default layoutSlice.reducer
