import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { ResponseResult } from "../../utils/interfaces/http/ResponseResult";
import { customAxiosApi, globalConfig } from "../../config/axios-retry";
import { PartnerRegistration } from "../../utils/interfaces/partner/PartnerRegistration";
import Toast from "../../shared/toast/Toast";


const appKey = 'appPartner'
const apiBaseUrl = 'pa/ft/partners'
const apiExternalPartnerRegistrationUrl = `${apiBaseUrl}/external-register`;
const apiExternalPartnerRegistrationDataUrl = `${apiBaseUrl}/external-registration`;

export const getExternalRegisterPartnerData = createAsyncThunk(`${appKey}/getExternalRegisterPartnerData`, async (token: string) => {
    const response = await customAxiosApi.get<ResponseResult<any>>(`${apiExternalPartnerRegistrationDataUrl}/${token}`, globalConfig);
    return response.data.data;
})

export const externalRegisterPartner = createAsyncThunk(`${appKey}/externalRegisterPartner`, async (body: PartnerRegistration, { dispatch }) => {
    const response = await customAxiosApi.post<ResponseResult<any>>(`${apiExternalPartnerRegistrationUrl}`, { ...body }, globalConfig);
    return response.data.data;
})

export const appExternalPartnerSlice = createSlice({
    name: appKey,
    initialState: {
        isLoading: true
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getExternalRegisterPartnerData.rejected, (state, action) => {
                let message = action?.error?.message;
                if (message === "Not Found" || message.includes('404')) {
                    message = t('REGISTRATION_TOKEN_INVALID');
                }
                Toast.Error({ content: message })
            })
            .addCase(externalRegisterPartner.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message || t('ERR_BAD_REQUEST') })
            })
    }
})

export default appExternalPartnerSlice.reducer
