import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../../config/axios';
import { ResponseResult } from "src/utils/interfaces/http/ResponseResult";
import Toast from "src/shared/toast/Toast";

const appKey = 'appWorkerHiredForm';
const apiLookupUrl = 'website/ft/workers/lookup-worker-applications';
const apiWebFormSubmissionUrl = 'website/ft/workers/worker-hired-form';

export const getWorkerHireLookups = createAsyncThunk(`${appKey}/getWorkerHireLookups`, async (params: any) => {
    const response = await axios.get<ResponseResult<any>>(`${apiLookupUrl}/${params.workerId}?${params.query}`);
    return response.data.data;
})

export const postWorkerData = createAsyncThunk(`${appKey}/postWorkerData`, async (body: any) => {
    const response = await axios.post<ResponseResult<any>>(`${apiWebFormSubmissionUrl}/${body.workerId}`, body);
    return response.data.data;
})

export const appWorkerHiredFormSlice = createSlice({
    name: appKey,
    initialState: {},
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(postWorkerData.rejected, (state, action) => {
                Toast.Error({ content: action.error?.message })
            })
    }
})

export default appWorkerHiredFormSlice.reducer
