export enum SubscriptionBillingCycleEnum {
    YEARLY = "YEARLY",
    MONTHLY = "MONTHLY",
}

export enum SubscriptionPlanEnum {
    FREE = "FREE",
    CORE = "CORE",
    RISE = 'RISE',
    GROWTH = "GROWTH",
    ENTERPRISE = "ENTERPRISE"
}

export enum SubscriptionStatusEnum {
    ACTIVE = "ACTIVE",
    ON_HOLD = "ON_HOLD",
    CANCELED = "CANCELED",
    INACTIVE = "INACTIVE",
}

export enum PartnerTrialStatusEnum {
    NOT_STARTED = 'NOT_STARTED',
    STARTED = 'STARTED',
    ENDED = 'ENDED',
}
