import Hotjar from '@hotjar/browser';
import * as Sentry from "@sentry/browser";
import { Suspense } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/scss/main.scss";
import "./assets/scss/style.scss";
import "./config/i18n/i18nConfig";
import { store } from "./redux/store";
import AppSpinnerComponent from "./shared/ui-elements/spinner/AppSpinnerComponent";
import lazyWithRetry from "./utils/components/lazyWithRetry";
import { GLITCH_TIP_DSN, HOTJAR_SITE_ID, HOTJAR_VERSION } from "./utils/constants/constants";

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({ dsn: GLITCH_TIP_DSN });
  // ReactGA.initialize(GA_MEASUREMENT_ID);
  Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const LazyApp = lazyWithRetry(() => import("./App"));
const isIframe = window.location !== window.parent.location;

root.render(
  <Provider store={store}>
    <Suspense fallback={isIframe ? <></> : <AppSpinnerComponent />}>
      <LazyApp />
      <ToastContainer limit={1} newestOnTop />
    </Suspense>
  </Provider>
);
