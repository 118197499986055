//@ts-nocheck

import { toast } from "react-toastify";
import { ToastContent } from "./Toast";
import { useMobile } from "../../utils/hooks/useMobile";

const SuccessToast: React.FC<{ content: any }> = ({ content }) => {

  const isMobile = useMobile()

  const toastConfig = {
    position: isMobile ? "top-center" : "bottom-center",
    autoClose: 3000,
    hideProgressBar: true
  }

  return toast.success(
    <ToastContent type={"success"} content={content} />,
    toastConfig
  );
};
export default SuccessToast;
