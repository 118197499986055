//@ts-nocheck

import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  text: string;
  value?: string | number | any;
}

const I18NText: React.FC<IProps> = ({ text, value }) => {
  const { t } = useTranslation();
  return t(text, { value });
};

export default I18NText;
