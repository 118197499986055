export enum PartnerInvitationResponseType {
    ACCEPTED = 'ACCEPTED',
    NEED_MORE_INFO = 'NEED_MORE_INFO',
    REJECTED = 'REJECTED',
}

export enum PartnerInvitationRejectionReason {
    HIRED_NOT_AVAILABLE = 'HIRED_NOT_AVAILABLE',
    SALARY = 'SALARY',
    LOCATION = 'LOCATION',
    JOB = 'JOB',
}