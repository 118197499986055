import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from '../../config/axios';
import Toast from '../../shared/toast/Toast';
import { globalConfig } from 'src/config/axios-retry';

const appKey = 'appFulltimePma'
const apiModuleUrl = 'pma/ft/payment';

export const getSubscriptionPlans = createAsyncThunk(`${appKey}/getSubscriptionPlans`, async (query: any) => {
    const response = await axios.get(`${apiModuleUrl}/subscription-plans?ftPartnerExternalId=${query.ftPartnerExternalId}`);
    return response.data.data;
});

export const getFtPartnerSubscriptionInfo = createAsyncThunk(`${appKey}/getFtPartnerSubscriptionInfo`, async (query: any) => {
    const response = await axios.get(`${apiModuleUrl}/ft-partner-subscription-info?ftPartnerExternalId=${query.ftPartnerExternalId}`);
    return response.data.data;
});

export const preparePurchaseProduct = createAsyncThunk(`${appKey}/preparePurchaseProduct`, async (body: any) => {
    const response = await axios.post(`${apiModuleUrl}/${body.planId}`, { ...body }, globalConfig);
    return response.data.data;
});

export const appFulltimePmaSlice = createSlice({
    name: appKey,
    initialState: {
        isLoadingSubscriptionPlans: true,
        isLoadingFtPartnerSubscriptionInfo: true,
        isLoadingPreparePurchaseProduct: false,

        subscriptionPlans: [],
        ftPartnerSubscriptionInfo: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getSubscriptionPlans.pending, (state) => {
                state.isLoadingSubscriptionPlans = true;
            })
            .addCase(getSubscriptionPlans.fulfilled, (state, action) => {
                state.isLoadingSubscriptionPlans = false;
                state.subscriptionPlans = action.payload;
            })
            .addCase(getFtPartnerSubscriptionInfo.fulfilled, (state, action) => {
                state.isLoadingFtPartnerSubscriptionInfo = false;
                state.ftPartnerSubscriptionInfo = action.payload;
                localStorage.setItem('specialLinkftPartnerSubscriptionInfo', JSON.stringify(action.payload));
            })
            .addCase(preparePurchaseProduct.rejected, (state, action) => {
                Toast.Error({ content: action.error?.message })
            })
    }
})

export default appFulltimePmaSlice.reducer
