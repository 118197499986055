import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { captureMessage } from '@sentry/browser';
import defaultAxios from 'axios';
import { t } from "i18next";
import axios from "../../config/axios";
import { customAxiosApi, globalConfig } from "../../config/axios-retry";
import Toast from "../../shared/toast/Toast";
import { LOOKUPS_URL } from '../../utils/helpers/utils';
import { ResponseResult } from "../../utils/interfaces/http/ResponseResult";
import { PartnerProfileStore } from "../../utils/interfaces/partner/Partner.store";
import { PartnerCompanyInfoUpdate } from '../../utils/interfaces/partner/PartnerCompanyInfoUpdate';
import { PartnerRegistration } from "../../utils/interfaces/partner/PartnerRegistration";
import { RegistrationLookup } from "../../utils/interfaces/partner/RegistrationLookup";
import { VATInfoInterface } from "../../utils/interfaces/partner/VATInfo";
import { FULLTIME_PARTNER_ID_KEY, PARTNER_STATUS, PARTNER_STATUS_MODAL_SHOWN, USER_LOCATION_COORDS } from './../../utils/constants/constants';

const appKey = 'appPartner'
const apiBaseUrl = 'pa/ft/partners'
const apiModuleUrl = `${apiBaseUrl}/me`;
const apiStatusUrl = `${apiBaseUrl}/status`;
const apiRegistrationLookups = `${apiBaseUrl}/registration-lookups`;
const apiPartnerRegistrationUrl = `${apiBaseUrl}/register`;
const apiVATUrl = `${apiBaseUrl}/vat-info`;
const apiCompanyProfileUpdateUrl = `${apiBaseUrl}/company-info`;
const apiSendEmailVerificationUrl = `ft/users/email/resend-verification`;
const apiUpdateCompanyLegalInfoUrl = `${apiBaseUrl}/update-company-legal-info`;
const apiUpdatePUNameUrl = `${apiBaseUrl}/update-partner-user-name`


export const getPartnerInfo = createAsyncThunk(`${appKey}/getPartnerInfo`, async () => {
    const response = await customAxiosApi.get(`${apiModuleUrl}`, globalConfig);
    return response.data;
})

export const getPartnerStatus = createAsyncThunk(`${appKey}/getPartnerStatus`, async () => {
    const response = await customAxiosApi.get(`${apiStatusUrl}`, globalConfig);
    return response.data.data;
})

export const getPartnerRegistrationLookups = createAsyncThunk(`${appKey}/getPartnerRegistrationLookups`, async () => {
    const response = await customAxiosApi.get<ResponseResult<RegistrationLookup>>(`${apiRegistrationLookups}`, globalConfig);
    return response.data.data;
})

export const getPaLookups = createAsyncThunk(`${appKey}/getPaLookups`, async () => {
    const response = await defaultAxios.get(`${LOOKUPS_URL()}`, { ...globalConfig, headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' } });
    if (response?.headers) {
        localStorage.setItem(USER_LOCATION_COORDS, JSON.stringify({ userLat: response.headers['user-lat'], userLng: response.headers['user-lng'], userCountry: response.headers['user-country'] }));
    }
    return response.data.pa;
})

export const registerPartner = createAsyncThunk(`${appKey}/registerPartner`, async (body: PartnerRegistration, { dispatch }) => {
    const response = await customAxiosApi.post<ResponseResult<any>>(`${apiPartnerRegistrationUrl}`, { ...body }, globalConfig);
    return response.data.data;
})

export const addPartnerVATInfo = createAsyncThunk(`${appKey}/addPartnerVATInfo`, async (body: VATInfoInterface, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiVATUrl}`, body);
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const updateCompanyProfile = createAsyncThunk(`${appKey}/updateCompanyProfile`, async (body: any, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiCompanyProfileUpdateUrl}`, { ...body });
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const completeCompanyOnboarding = createAsyncThunk(`${appKey}/completeCompanyOnboarding`, async (body: PartnerCompanyInfoUpdate, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiCompanyProfileUpdateUrl}`, { ...body });
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const sendEmailVerification = createAsyncThunk(`${appKey}/sendEmailVerification`, async (body: any, { dispatch }) => {
    const response = await axios.get<ResponseResult<any>>(`${apiSendEmailVerificationUrl}`);
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})


export const updateCompanyLegalInfo = createAsyncThunk(`${appKey}/updateCompanyLegalInfo`, async (body: any, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiUpdateCompanyLegalInfoUrl}`, { ...body });
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const updatePartnerUserName = createAsyncThunk(`${appKey}/updatePartnerUserName`, async (body: any, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiUpdatePUNameUrl}`, { ...body });
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

const initialState: PartnerProfileStore = {
    isLoading: true,
    data: null,
    lookup: null,
    showCompanyProfilePopup: false,
    showInCompleteCompanyProfileMPopup: false,
}

export const appPartnerSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {
        handleCompanyProfileModal: (state, action) => {
            state.showCompanyProfilePopup = action.payload
        },
        handleInCompleteCompanyProfileModal: (state, action) => {
            state.showInCompleteCompanyProfileMPopup = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPartnerInfo.fulfilled, (state, action) => {
                state.data = action.payload;
                const partner = action.payload.partners?.[0];
                const lsPartnerStatus = localStorage.getItem(PARTNER_STATUS);
                if (lsPartnerStatus === null) {
                    localStorage.setItem(PARTNER_STATUS, partner.status);
                    localStorage.setItem(PARTNER_STATUS_MODAL_SHOWN, 'false');
                }
            })
            .addCase(getPartnerRegistrationLookups.fulfilled, (state, action) => {
                state.lookup = action.payload;
            })
            .addCase(addPartnerVATInfo.fulfilled, (state, action) => {
                state.data = action.payload;
                Toast.Success({ content: t('vat_info_confirmation_message') })
            })
            .addCase(addPartnerVATInfo.rejected, (state, action) => {
                Toast.Error({ content: action.error?.message })
            })
            .addCase(updateCompanyProfile.fulfilled, (state, action) => {
                Toast.Success({ content: t('company_profile_updated') })
            })
            .addCase(updateCompanyProfile.rejected, (state, action) => {
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Unprocessable Entity') errorMessage = t('not_allowed');
                Toast.Error({ content: errorMessage });
                // setTimeout(() => {
                //     window.location.href = '/#/jobs';
                //     window.location.reload();
                // }, 5000);
            })
            .addCase(completeCompanyOnboarding.fulfilled, (state, action) => {
                Toast.Success({ content: t('completed_registration_confirmation_message') })
            })
            .addCase(completeCompanyOnboarding.rejected, (state, action) => {
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Unprocessable Entity') errorMessage = t('not_allowed');
                Toast.Error({ content: errorMessage });
                setTimeout(() => {
                    window.location.href = '/#/jobs';
                    window.location.reload();
                }, 5000);
            })
            .addCase(registerPartner.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message || t('ERR_BAD_REQUEST') })
            })
            .addCase(sendEmailVerification.fulfilled, (state, action) => {
                Toast.Success({ content: t('email_verification_sent') })
            })
            .addCase(sendEmailVerification.rejected, (state, action) => {
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Invalid Request') errorMessage = t('email_already_verified');
                Toast.Error({ content: errorMessage });
            })
            .addCase(updateCompanyLegalInfo.fulfilled, (state, action) => {
                Toast.Success({ content: t('updated_company_legal_info') })
            })
            .addCase(updateCompanyLegalInfo.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message })
            })
            .addCase(updatePartnerUserName.fulfilled, (state, action) => {
                Toast.Success({ content: t('updated_partner_user_name_message') })
            })
            .addCase(updatePartnerUserName.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.code })
            })
    }
})

export const {
    handleCompanyProfileModal,
    handleInCompleteCompanyProfileModal
} = appPartnerSlice.actions

export default appPartnerSlice.reducer
