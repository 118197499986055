// ** Redux Imports
import { combineReducers } from '@reduxjs/toolkit'
import appReducer from './appReducer'

const combinedReducers = combineReducers(appReducer)

const rootReducer = (state: any, action: any) => {
    return combinedReducers(state, action)
}

export default rootReducer;