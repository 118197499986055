
export const VAT_PERCENTAGE_IN_SAUDI = 15
export const INITIAL_PAYMENT = 300
export const FULLTIME_PARTNER_ID_KEY = 'fulltime-partner-id'
export const FULLTIME_PARTNER_TOKEN_KEY = 'fulltime-partner-token'
export const APP_VERSION_KEY = 'app-version'
export const LOOKUPS_VERSION_KEY = 'lookups-version'
export const WORKER_ID_KEY = 'worker-id'
export const WORKER_TOKEN_KEY = 'worker-token'
export const IS_SABBAR_APPROVED = 'is-sabbar-approved'
export const PARTNER_STATUS = 'partner-status';
export const PARTNER_STATUS_MODAL_SHOWN = 'partner-status-modal-shown';
export const AGGREGATED_PARTNER_KEY = 'aggregated-partner';
export const APPLICANTS_PAGE_LIMIT = 10;
export const CACHED_APPLICANTS_KEY = 'cached-applicants';
export const APPLICANTS_CACHE_INTERVAL = 180; // 3 minutes
export const CHATS_PAGE_LIMIT = 10;

export const COOKIE_UTMDATA = "_utmtrackdata";
export const COOKIE_FIRST_UTM = "utm_first_visit";
export const COOKIE_LAST_UTM = "utm_last_visit";
export const COOKIE_SAME_UTM = "utm_first_sameas_utm_last";

export const AppStoreLinkAr = "https://apps.apple.com/sa/app/%D8%B5%D8%A8-%D8%A7%D8%B1-%D9%84%D9%84%D9%88%D8%B8%D8%A7%D8%A6%D9%81/id1449471472?l=ar"
export const AppStoreLinkEn = "https://apps.apple.com/sa/app/sabbar-jobs/id1449471472";

export const PlayStoreLinkAr = "https://play.google.com/store/apps/details?id=com.sabbar.seeker&hl=ar&gl=SA&pli=1";
export const PlayStoreLinkEn = "https://play.google.com/store/apps/details?id=com.sabbar.seeker&hl=en&gl=SA&pli=1";

export const DownloadAPPLinkEn = "https://sabbar.com/en/download-our-app?utm_source=website&utm_medium=social&utm_campaign=lead-sign-up-new-model-pwa-en2023"
export const DownloadAPPLinkAr = "https://sabbar.com/ar/download-our-app?utm_source=website&utm_medium=social&utm_campaign=lead-sign-up-new-model-pwa-en2023"

export const SABBAR_PROFILE_PDF_URL = "https://sabbar-prod-uploaded-files.s3.eu-west-1.amazonaws.com/static-files/email/Sabbar_SME.pdf"

//https://qjerf5r25qo.typeform.com/to/okHBKQey
export const JobPostTypoFormUrl = () => {
  return `https://qjerf5r25qo.typeform.com/to/IS8F8vwT#new_partner_id=${localStorage.getItem(FULLTIME_PARTNER_ID_KEY)}`;
}

export const WhatsAppContactMessage = (applicantName, jobTitle, partnerName) => {
  return `\u0627\u0644\u0633\u0644\u0627\u0645%20\u0639\u0644\u064A\u0643\u0645%20${applicantName}%0A\u0623\u062A\u0648\u0627\u0635\u0644%20\u0645\u0639\u0643%20\u0628\u062E\u0635\u0648\u0635%20\u062A\u0642\u062F\u064A\u0645\u0643%20\u0639\u0646%20\u0637\u0631\u064A\u0642%20\u0645\u0646\u0635\u0629%20\u0635\u0628\u0651\u0627\u0631%20\u0644\u0648\u0638\u064A\u0641\u0629%20${jobTitle}%20\u0641\u064A%20\u0634\u0631\u0643\u0629%20${partnerName}`
}

export const urlRegex = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');

export const MobileNumberPattern = { SA: '^9665[0-9]{8}$' }
export const mobileRegex = '^5[0-9]{8}$'

export const GA_MEASUREMENT_ID = "G-7CSR8WT4C8"

export const GOOGLE_MAP_API_KEY = "AIzaSyA37FnLtf9Sp1Sliu8RoLl99VrouNsRq4Y";

export const WHATSAPP_NUMBER = "966920011375";

export const APP_URL = process.env.REACT_APP_URL
export const GLITCH_TIP_DSN = "https://3070fdf0c18842bd903f615ee3aab75a@app.glitchtip.com/3536";
export const HOTJAR_SITE_ID = 3599538;
export const HOTJAR_VERSION = 6;
export const KSA_COUNTRY_CODE = 966;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const APP_VERSION_NUMBER = "212";
export const LOOKUPS_VERSION_NUMBER = "5";
export const APPLICANT_FILTER_KEY = 'applicant-filter-v2';
export const APPLE_PAY_VERSION = 5;
export const AGORA_APP_ID = 'af9aaf16de0b46ebab56105a8156abf3';
export const SABBAR_VIDEO_URL = 'https://sabbar-prod-uploaded-files.s3.amazonaws.com/static-files/sabbar_intro.mp4';
export const LOOKUPS_DATA_LS_KEY = 'paLookups';
export const USER_LOCATION_COORDS = 'userLocationCoords';
export const API_TIMEOUT = 8000;
export const TYPESENSE_HOST = 'psugx4b3vywilkr1p.a1.typesense.net';
export const TYPESENSE_APIKEY = 'hqayDcTSs9sNG9JEBLXfvnVKljjYIUJV';
export const TYPESENSE_CONFIG = {
  headers: {
    'Content-Type': 'text/plain'
  },
  timeout: API_TIMEOUT
}
export const MAX_WORKER_SALARY = 10000;