import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from '../../config/axios';
import Toast from '../../shared/toast/Toast';
import { PreparePurchaseProductRequest } from '../../utils/interfaces/payment/PreparePurchaseProductRequest';

const appKey = 'appFulltimePayment'
const apiGetPaymentStatusUrl = 'pa/ft/payment/status';
const invoiceUrl = 'pa/ft/payment/invoice';
const applePayBaseUrl = 'pa/ft/payment/apple-pay';
const invoiceListUrl = 'pa/ft/payment/invoices/list';

export const getPaymentStatus = createAsyncThunk(`${appKey}/getPaymentStatus`, async (paymentRefId: string) => {
    const response = await axios.get(`${apiGetPaymentStatusUrl}/${paymentRefId}`);
    return response.data.data;
});

export const getInvoice = createAsyncThunk(`${appKey}/getInvoice`, async (paymentRefId: string) => {
    const response = await axios.get(`${invoiceUrl}/view/${paymentRefId}`);
    return response.data.data;
});

export const downloadInvoice = createAsyncThunk(`${appKey}/downloadInvoice`, async (paymentRefId: string) => {
    const response = await axios.get(`${invoiceUrl}/download/${paymentRefId}`, { responseType: 'blob' });
    return response.data;
});

export const updatePaymentModalTitle = createAction<string>(`${appKey}/updatePaymentModalTitle`);

export const validateApplePaySession = createAsyncThunk(`${appKey}/validateApplePaySession`, async (data: any) => {
    const response = await axios.post(`${applePayBaseUrl}/validate-session`, data);
    return response.data;
});

export const payViaApplePay = createAsyncThunk(`${appKey}/payViaApplePay`, async (data: any) => {
    const response = await axios.post(`${applePayBaseUrl}/pay`, data);
    return response.data;
});

export const getInvoiceList = createAsyncThunk(`${appKey}/getInvoiceList`, async () => {
    const response = await axios.get(invoiceListUrl);
    return response.data.data;
});

export const appFulltimePaymentSlice = createSlice({
    name: appKey,
    initialState: {
        isLoading: true,
        data: {},
        invoicesList: [],
        paymentModalTitle: 'pay_to_unlock'
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getInvoice.rejected, (state, action) => {
                // Toast.Error({ content: action.error?.message })
            })
            .addCase(downloadInvoice.rejected, (state, action) => {
                // Toast.Error({ content: action.error?.message })
            })
            .addCase(updatePaymentModalTitle, (state, action) => {
                state.paymentModalTitle = action.payload;
            })
            .addCase(getInvoiceList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.invoicesList = action.payload;
            })
            .addCase(getInvoiceList.rejected, (state, action) => {
                state.isLoading = false;
                Toast.Error({ content: action.error?.message })
            })
    }
})

export default appFulltimePaymentSlice.reducer
