import { Buffer } from "buffer";
import { fromUnixTime } from "date-fns";
import { t } from "i18next";
import { APPLICANTS_CACHE_INTERVAL, CACHED_APPLICANTS_KEY, LOOKUPS_VERSION_NUMBER, VAT_PERCENTAGE_IN_SAUDI } from "../constants/constants";
import { countries } from "../constants/countries";
import { iconChatMessages, iconCheckGreen, iconCloseFilled, iconFire, iconHelp } from "../constants/icons";
import { ApplicantGenderEnum } from "../enums/ApplicantGender";
import { ChatApplicationUpdateType } from "../enums/ChatApplicationUpdateTypeEnum";
import { ChatMessageType } from "../enums/ChatMessageTypeEnum";
import { FileTypeEnum } from "../enums/FileTypeEnum";
import { GenderEnum } from "../enums/Gender";
import { LookupsSearchIndexEnum } from "../enums/LookupsSearchIndexEnum";
import { PMAEventEnum } from "../enums/PMAEventEnum";
import { PartnerInvitationResponseType } from "../enums/PartnerInvitationResponseTypeEnum";
import { PartnerStatus } from "../enums/PartnerStatus";
import { SubscriptionBillingCycleEnum, SubscriptionStatusEnum } from "../enums/PartnerSubscription";
import { WorkerExperienceDurationEnum } from "../enums/WorkerExperienceDuration";
import { ApplicantListItem } from "../interfaces/applicants/ApplicantListItem";
import { CachedApplicantItem } from "../interfaces/applicants/CachedApplicantItem";
import { ChatMessageApplicantDetails, LatestMsg } from "../interfaces/chat/ChatListItem";
import { PMAEventArgsInterface } from "../interfaces/chat/PMAEventArgsInterface";
import { PaLookups } from "../interfaces/partner/PaLookups";
import { PartnerIntercomData } from "../interfaces/partner/PartnerIntercomData";
import { Partner, PartnerUser, SubscriptionPlan } from "../interfaces/partner/PartnerProfile";
import { fromUnixTimestamp } from "./date-helper";

const arabicNumbersMap = {
    '٠': '0', '۱': '1', '١': '1',
    '۲': '2', '٢': '2',
    '٣': '3', '٤': '4', '٥': '5', '٦': '6',
    '٧': '7', '٨': '8',
    '۹': '9', '٩': '9'
};

export const defaultUserAvatar = (gender: GenderEnum, style = 'circle') => {
    if (gender && gender.toUpperCase() === GenderEnum.FEMALE) {
        return style === 'circle' ? require("@src/assets/images/avatars/female-circle.svg").default : require("@src/assets/images/avatars/female-square.svg").default;
    }
    return style === 'circle' ? require("@src/assets/images/avatars/male-circle.svg").default : require("@src/assets/images/avatars/male-square.svg").default;
}

export const getApplicantGender = (gender: string) => {
    return gender
        ? gender === ApplicantGenderEnum.male ||
            gender?.toLowerCase() === GenderEnum.MALE.toLowerCase()
            ? GenderEnum.MALE
            : GenderEnum.FEMALE
        : GenderEnum.MALE;
}

export const setCookie = (cname: string, cvalue: any, days: number) => {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires}`;
}

export const getCookie = (cname: string) => {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const extractDigit = (string: any) => string.match(/\d+/)[0];

export const convertArabicNumbersToEnglish = (value: any) => {
    value = value.replace(/[٠۱١۲٢٣٤٥٦٧٨۹٩]/g, (match: any) => {
        //@ts-ignore
        return arabicNumbersMap[match];
    });
    return value;
}

export const isArabic = (str: any) => {
    const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    return pattern.test(str);
}

export const isProduction = () => process.env.REACT_APP_ENV === 'production';
export const LOOKUPS_URL = () => process.env.REACT_APP_ENV === 'production' ? `https://cdn.sabbar.com/static-files/lookups.json?v=${LOOKUPS_VERSION_NUMBER}` : `https://cdn.giglaya.com/static-files/lookups.json?v=${LOOKUPS_VERSION_NUMBER}`;

export const isNotDemoPost = (status: string) => status !== "DEMO_POST";
export const isNotDraftPost = (status: string) => status !== "DRAFT";

export const getStatusColor = (status: string, isLightBG: boolean = true) => {

    const prefixKey = isLightBG ? 'light-' : '';

    const statusObj = {
        OPEN: `${prefixKey}success`,
        ACTIVE: `${prefixKey}success`,
        APPROVED: `${prefixKey}success`,
        UNDER_REVIEW: `${prefixKey}warning`,
        CLOSED: `${prefixKey}secondary`,
        REJECTED: `${prefixKey}danger`,
        DEMO_POST: `${prefixKey}info-2`,
        NOT_PROVIDED: `${prefixKey}info-2`,
        PENDING_APPROVAL: `${prefixKey}info-2`,
        EXPIRED: `${prefixKey}danger`,
        PENDING_REVIEW: `${prefixKey}warning`,
    }

    return statusObj[status];
}


export const dataUrlToFile = (url: string, fileName: string) => {
    const [mediaType, data] = url.split(",");
    const mime = mediaType.match(/:(.*?);/)?.[0];
    let n = data.length;
    const arr = new Uint8Array(n);
    while (n--) {
        arr[n] = data.charCodeAt(n);
    }
    return new File([arr], fileName, { type: mime });
};

export const dataURItoBlob = (dataURI: any) => {
    const byteString = Buffer.from(dataURI.split(',')[1], 'base64');
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString[i];
    }

    return new Blob([ia], { type: mimeString });
}

export const toCapitalize = (string: string) => {
    if (!string) return;
    const arr = string.toLowerCase().split(' ');
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const string2 = arr.join(' ');
    return string2;
}

export const enumToArray = (item, prefixKey = "enum_") => {
    const list = Object.keys(item);
    const options = list.map((type) => {
        return { value: item[type], label: t(`${prefixKey}${type}`) };
    });
    return options;
}

export const enumToArrayWithDescription = (item, prefixKey = "enum_") => {
    const list = Object.keys(item);
    const options = list.map((type) => {
        return { value: item[type], label: t(`${prefixKey}${type}`), description: t(`${prefixKey}${type}_description`) };
    });
    return options;
}

export const GetCenterPoint = (latLng: any) => {
    if (latLng === undefined) return;
    const center = { lat: latLng.lat(), long: latLng.lng() };
    const centerPoint = [parseFloat(center.lat.toFixed(6)), parseFloat(center.long.toFixed(6))];
    return { centerPoint, center }
}

export const convertArrayToObjectKeyValue = (items: any[]) => {
    const objectKeyValuePair = items.reduce(function (result, item) {
        var key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
    }, {});
    return objectKeyValuePair;
}

export const getAge = (dob: number) => {
    const dateOfBirth = fromUnixTime(dob);
    const month_diff = Date.now() - dateOfBirth.getTime();
    const age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    return age;
}

export const querySearchParams = (items) => {
    let query = new URLSearchParams();

    Object.entries(items).forEach(([k, v]) => {
        if (Array.isArray(v)) {
            v.forEach((vv) => {
                query.append(`${k}[]`, vv);
            });
        } else {
            //@ts-ignore
            query.append(k, v);
        }
    });
    return query;
};

export const getExperienceDurationMapping = () => {
    return [
        {
            value: WorkerExperienceDurationEnum.LESS_3_MONTHS,
            label: t(`enum_${WorkerExperienceDurationEnum.LESS_3_MONTHS}`)
        },
        {
            value: WorkerExperienceDurationEnum.LESS_6_MONTHS,
            label: t(`enum_${WorkerExperienceDurationEnum.LESS_6_MONTHS}`)
        },
        {
            value: WorkerExperienceDurationEnum.LESS_1_YEAR,
            label: t(`enum_${WorkerExperienceDurationEnum.LESS_1_YEAR}`)
        },
        {
            value: WorkerExperienceDurationEnum.LESS_2_YEARS,
            label: t(`enum_${WorkerExperienceDurationEnum.LESS_2_YEARS}`)
        },
        {
            value: WorkerExperienceDurationEnum.MORE_2_YEARS,
            label: t(`enum_${WorkerExperienceDurationEnum.MORE_2_YEARS}`)
        }
    ]
}

export const getAddressFromCoordinates = async (lat: number, lng: number): Promise<{ locationEn, locationAr }> => {
    if (lat && lng) {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(lat, lng);

        const [locationEn, locationAr] = await Promise.all([
            geocoder.geocode({ location: latlng, language: 'en' }, (results, status) => {
                if (results?.length > 0 && status === google.maps.GeocoderStatus.OK) {
                    const address = results[0].formatted_address;
                    const country = results[0].address_components.find(x => x.types.find(y => y === 'country'))?.short_name;
                    const city = results[0].address_components.find(x => x.types.find(y => y === 'locality'))?.long_name;
                    const district = results[0].address_components.find(x => x.types.find(y => y === 'sublocality'))?.long_name;
                    const placeId = results[0].place_id;
                    const location = { country, address, city, district, location: latlng, placeId };
                    return location;
                }
            }),
            geocoder.geocode({ location: latlng, language: 'ar' }, (results, status) => {
                if (results?.length > 0 && status === google.maps.GeocoderStatus.OK) {
                    const address = results[0].formatted_address;
                    const country = results[0].address_components.find(x => x.types.find(y => y === 'country'))?.short_name;
                    const city = results[0].address_components.find(x => x.types.find(y => y === 'locality'))?.long_name;
                    const district = results[0].address_components.find(x => x.types.find(y => y === 'sublocality'))?.long_name;
                    const placeId = results[0].place_id;
                    const location = { country, address, city, district, location: latlng, placeId };
                    return location;
                }
            })
        ]);
        return { locationEn, locationAr }
    }
};

export const getMatchingAddressBetweenGoogleAndUser = (addresses: any, selectedAddress: any) => {
    if (addresses?.locationEn?.results?.length > 0) {
        const enAddresses = addresses?.locationEn?.results;
        const arAddresses = addresses?.locationAr?.results;
        if (enAddresses?.length > 0 && arAddresses?.length > 0) {
            let matchSelectedAddress = { en: enAddresses[0], ar: arAddresses[0] };
            const matchEnSelectedAddressIndex = enAddresses.findIndex(res => res?.formatted_address === selectedAddress);
            const matchArSelectedAddressIndex = arAddresses.findIndex(res => res?.formatted_address === selectedAddress);
            if (matchEnSelectedAddressIndex && matchEnSelectedAddressIndex !== -1) {
                matchSelectedAddress = { en: enAddresses[matchEnSelectedAddressIndex], ar: arAddresses[matchEnSelectedAddressIndex] };
            } else if (matchArSelectedAddressIndex && matchArSelectedAddressIndex !== -1) {
                matchSelectedAddress = { en: enAddresses[matchArSelectedAddressIndex], ar: arAddresses[matchArSelectedAddressIndex] };
            }
            return { en: matchSelectedAddress?.en?.formatted_address, ar: matchSelectedAddress?.ar?.formatted_address };
        }
    }
    return {};
}

export const getGoogleCityFromLocation = (addresses: any) => {
    if (addresses?.locationEn?.results?.length > 0) {
        const enAddresses = addresses?.locationEn?.results;
        const arAddresses = addresses?.locationAr?.results;
        if (enAddresses?.length > 0 && arAddresses?.length > 0) {
            const cityEn = enAddresses[0]?.address_components?.find(x => x.types.find(y => y === 'locality'))?.long_name;
            const cityAr = arAddresses[0]?.address_components?.find(x => x.types.find(y => y === 'locality'))?.long_name;
            return { en: cityEn, ar: cityAr };
        }
    }
    return {};
}

export const getLocationDisplayName = (locationInfo: any, lang: any) => {
    return locationInfo?.localizedAddress?.[lang] || locationInfo?.address || locationInfo?.city || null;
}

export const logoutUser = () => {
    localStorage.clear();
    window.location.href = '/#/login';
}

export const escapeHTML = (text: string) => {
    return text ? text.replace(/(<([^>]+)>)/ig, '') : ""
}

export const getLookupLocationDb = (): LookupsSearchIndexEnum => {
    return process.env.REACT_APP_ENV === 'production' ? LookupsSearchIndexEnum.PROD_GEO : LookupsSearchIndexEnum.PROD_GEO// LookupsSearchIndexEnum.STG_GEO; // currently stg_geo is disabled for typesense
}

export const sortArrayByLabel = (items: any, key: string) => {
    return items.sort((row1, row2) => {
        let x = row1[key]?.toLowerCase();
        let y = row2[key]?.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    });
}

export const sortObjectKeys = (objToSort: any) => {
    let obj = {};
    Object.keys(objToSort)
        .sort()
        .forEach(function (v, i) {
            obj[v] = objToSort[v];
        });
    return obj;
}

const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};


export const getCacheKeyForObject = (obj: any, entityNamePrefix?: string) => {
    let cacheKey = entityNamePrefix ? `${entityNamePrefix}-` : 'ck-'; //ck for cache key
    if (Object.keys(obj).length) {
        const sortedString = JSON.stringify(sortObjectKeys(obj));
        const hashedKey = cyrb53(sortedString)
        cacheKey += `${hashedKey}`;
    } else {
        return '';
    }
    return cacheKey;
}

export const clearApplicantsCacheKeys = (filterKey: 'timestamp' | 'jobId', jobId?: string) => {
    let applicantsFromLSCache: CachedApplicantItem[] = localStorage.getItem(CACHED_APPLICANTS_KEY) ? JSON.parse(localStorage.getItem(CACHED_APPLICANTS_KEY)) : [];
    if (applicantsFromLSCache.length === 0) return;

    let newApplicantsFromLSCache = [...applicantsFromLSCache];
    const currentTimestamp = Math.ceil(Date.now() / 1000);

    if (filterKey === 'timestamp') {
        newApplicantsFromLSCache = newApplicantsFromLSCache.filter(ap => {
            if (currentTimestamp - ap.timestamp > APPLICANTS_CACHE_INTERVAL) {
                return false;
            }
            return true;
        });
    } else if (filterKey === 'jobId' && jobId) {
        newApplicantsFromLSCache = newApplicantsFromLSCache.filter(ap => ap.jobId !== jobId);
    }

    localStorage.setItem(CACHED_APPLICANTS_KEY, JSON.stringify(newApplicantsFromLSCache));
}

export const getApplicantNationality = (countryCode: string, gender: string, lang: string): string => {
    let nationality = '';
    const country = countries.find(c => c.code === countryCode);
    if (country) {
        if (lang === 'en') {
            nationality = country.nationalityEn;
        } else {
            if (gender === 'FEMALE') {
                nationality = country.nationalityArFemale;
            } else {
                nationality = country.nationalityAr;
            }
        }
    }

    return nationality;
}

export const handleScrollToTop = (top = 0) => {
    window.scroll({ top: top, behavior: 'smooth' })
}

export const addHotjarTrackingForSelect = () => {
    document.getElementById('react-select-2-input')?.classList?.add('data-hj-allow');
    document.getElementById('react-select-3-input')?.classList?.add('data-hj-allow');
    document.getElementById('react-select-4-input')?.classList?.add('data-hj-allow');
    document.getElementById('react-select-5-input')?.classList?.add('data-hj-allow');
    document.getElementById('react-select-6-input')?.classList?.add('data-hj-allow');
    document.getElementById('react-select-7-input')?.classList?.add('data-hj-allow');
    document.getElementById('react-select-8-input')?.classList?.add('data-hj-allow');
}

export const isSameObject = (obj1: any, obj2: any) => {

    const sortedObj1 = sortObjectKeys(obj1);
    const sortedObj2 = sortObjectKeys(obj2);

    return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2);
}

export const groupByData = (data: any, key: string, key2: string = "") => {
    const groups = data.reduce((items: any, value: any) => {
        const item = key2 ? value[key][key2] : value[key];
        if (!items[item]) {
            items[item] = [];
        }
        items[item].push(value);
        return items;
    }, {});

    const groupArrays = key2 ? Object.keys(groups).map((key2) => {
        return {
            label: key2,
            options: groups[key2]
        };
    }) : Object.keys(groups).map((key) => {
        return {
            label: key,
            options: groups[key]
        };
    });

    return groupArrays
}

export const subSectorsGroupedBySector = (payload, key, lang) => {
    const result = payload.map(hit => {
        return {
            ...hit,
            displayName: hit?.highlight?.name?.[lang]?.value?.replace(/mark/g, 'em') || hit?.document?.name?.[lang],
            objectID: hit?.document?.id,
            industryEnum: hit?.document?.industryEnum,
            industry: hit?.document?.industry
        }
    });

    const group = groupByData(result, key);

    const formattedGroup = [];

    group.forEach(x => formattedGroup.push({
        ...x,
        label: x.options?.length > 0 ? x.options?.[0]?.industry?.[lang] : x.label,
    }));
    return formattedGroup
}

export const groupedBySkills = (payload, key, lang) => {
    const result = payload.map(hit => {
        return {
            ...hit,
            label: hit?.highlight?.name?.[lang]?.value?.replace(/mark/g, 'em') || hit?.document?.name?.[lang],
            value: hit?.objectID
        }
    });

    const group = groupByData(result, key);

    return group
}

export const removeDuplicatesFromArray = (items: string[], key: string) => {
    return items.length > 0 ? Array.from(new Set(items.map(x => x[key]))) : []
}

export const removeDuplicatesFromArrayOfObjects = (items: any, key: string) => {
    //@ts-ignore
    return [...new Map(items.map(item => [item[key], item])).values()];
}

export const preparePartnerHotjarObject = (partner: Partner, partnerUser: PartnerUser) => {
    const name = partnerUser?.name;
    const firstName = name?.split(' ')[0];
    const lastName = name?.substring(firstName?.length)?.trim();

    return {
        'partnerUserId': partnerUser?.id?.split('-')?.[0],
        'partnerId': partner.reference,
        'brandName': partner.name,
        'firstName': firstName,
        'lastName': lastName,
        'mobile': partnerUser?.mobile,
        'email': partnerUser?.email,
        'leadSource': partner.leadSource,
        'industry': partner.industriesAndSubIndustriesEn,
        'businessSize': partner.employeeCount,
        'hqLocation': partner.location,
        'registeredAt': partner.registrationDate,
        'isQualified': partner.isQualified,
        'isCompany': partner.status === PartnerStatus.APPROVED,
        'status': partner.status,
        'totalJobs': partner.jobPostsCount,
        'totalPaidJobs': partner.paidJobPostsCount,
        'subscriptionPlan': partner?.currentSubscription?.subscriptionCategory || 'FREE',
        'subscriptionDuration': partner?.currentSubscription?.billingCycle || null,
        'subscriptionStatus': partner?.currentSubscription?.subscriptionStatus || null,
        'subscriptionStartAt': partner?.currentSubscription?.subscriptionBillingCycleStartAt ? fromUnixTimestamp(partner?.currentSubscription?.subscriptionBillingCycleStartAt, 'yyyy-MM-dd') : null,
        'subscriptionEndAt': partner?.currentSubscription?.subscriptionBillingCycleEndAt ? fromUnixTimestamp(partner?.currentSubscription?.subscriptionBillingCycleEndAt, 'yyyy-MM-dd') : null,
        'totalSubscriptions': partner?.currentSubscription?.totalSubscriptionsCount || 0,
        'totalSales': partner?.currentSubscription?.totalSales || 0,
    }
}

export const preparePartnerIntercomObject = (partner: Partner, partnerUser: PartnerUser, lang: string): PartnerIntercomData => {
    return {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        language_override: lang,
        user_id: partnerUser.id,
        name: partnerUser.name,
        email: partnerUser.email,
        user_hash: partnerUser.intercomUserIdHash,
        created_at: partnerUser.createdAt,
        phone: partnerUser.mobile,
        company: {
            company_id: partner.partnerId,
            created_at: partner.registrationDate,
            name: partner.name,
            plan: partner?.currentSubscription?.subscriptionCategory || 'FREE',
            industry: partner.industriesAndSubIndustriesEn ? partner.industriesAndSubIndustriesEn : null,
            total_jobs: partner.jobPostsCount,
            business_size: partner.employeeCount,
            hq_location: partner?.location,
            lead_source: partner.leadSource,
            status: partner.status,
            subscription_plan: partner?.currentSubscription?.subscriptionCategory || 'FREE',
            subscription_duration: partner?.currentSubscription?.billingCycle || null,
            subscription_status: partner?.currentSubscription?.subscriptionStatus || null,
            subscription_start_at: partner?.currentSubscription?.subscriptionBillingCycleStartAt || null,
            subscription_end_at: partner?.currentSubscription?.subscriptionBillingCycleEndAt || null,
            total_subscriptions: partner?.currentSubscription?.totalSubscriptionsCount || 0,
            total_sales: partner?.currentSubscription?.totalSales || '0',
        },
    }
}

export const preparePartnerIntercomObjectBE = (partner: Partner, partnerUser: PartnerUser, lang: string): PartnerIntercomData => {
    return {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        language_override: lang,
        user_id: partnerUser.id,
        name: partnerUser.name,
        email: partnerUser.email,
        user_hash: partnerUser.intercomUserIdHash,
        created_at: partnerUser.createdAt
    }
}

export const preparePartnerIntercomObjectForVisitor = (): PartnerIntercomData => {
    return {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_APP_ID
    }
}

export const getPlanPrice = (plan: SubscriptionPlan, billingCycle: SubscriptionBillingCycleEnum, isUnsubscribedPartner: boolean): { subtotal: number, vatAmount: number, total: number } => {
    const price = billingCycle === SubscriptionBillingCycleEnum.YEARLY ? plan.yearlyBillingPrice : plan.monthlyBillingPrice;
    const vatPercantage = VAT_PERCENTAGE_IN_SAUDI / 100;

    if (isUnsubscribedPartner && plan.isDiscountActive && plan.discount.applicableBillingCycles.includes(billingCycle)) {
        const subtotal = price - (price * (plan.discount.percentage / 100));
        const vatAmount = Math.round(subtotal) * vatPercantage;
        return {
            subtotal: Math.round(subtotal),
            vatAmount: Math.round(vatAmount),
            total: Math.round(subtotal) + Math.round(vatAmount)
        }
    }

    const subtotal = price;
    const vatAmount = Math.round(subtotal) * vatPercantage;
    return {
        subtotal: Math.round(subtotal),
        vatAmount: Math.round(vatAmount),
        total: Math.round(subtotal) + Math.round(vatAmount)
    }
}

export const isLocationStateValid = (state: any) => {
    if (!state) return false;
    if (typeof state !== "object") return false;
    return true;
}

export const addAudioElement = (blob: Blob, container: HTMLCollection, classNames?: string) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    audio.className = classNames;
    container[0].appendChild(audio);
};

export const callPmaEvent = (pmaEvent: PMAEventEnum, args: PMAEventArgsInterface) => {
    if (!window.flutter_inappwebview) return;
    window.flutter_inappwebview.callHandler(pmaEvent, args);
}

export const getFileName = (path: string) => {
    // https://stackoverflow.com/a/25221100
    return path.replace(/^.*[\\/]/, '')
}

export const imageExtensions = ['png', 'jpg', 'jpeg'];
export const videoExtensions = ['mp4', 'mov'];
export const documentExtensions = ['pdf', 'doc', 'docx', 'xlsx', 'txt'];
export const voiceExtensions = ['mp3', 'opus', 'wav', 'm4a'];

export const getLatestChatMsgPreview = (latestMsg: LatestMsg, applicant: any): { msgType: number, msg: string } => {
    if (!latestMsg) return null;
    if (latestMsg.msgType === ChatMessageType.TEXT) {
        return { msgType: latestMsg.msgType, msg: latestMsg.msg };
    }

    if (latestMsg.fileUrl) {
        const fileExtensionArray = latestMsg.fileUrl.split('.');
        const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        if (imageExtensions.includes(fileExtension)) {
            return { msgType: 1, msg: FileTypeEnum.IMAGE as string }
        } else if (videoExtensions.includes(fileExtension)) {
            return { msgType: 1, msg: FileTypeEnum.VIDEO as string }
        } else if (documentExtensions.includes(fileExtension)) {
            return { msgType: 1, msg: FileTypeEnum.DOCUMENT as string }
        } else if (voiceExtensions.includes(fileExtension)) {
            return { msgType: 2, msg: FileTypeEnum.VOICE as string }
        }
    }
    if (latestMsg.msgType === ChatMessageType.APPLICATION_UPDATE) {
        return { msgType: latestMsg.msgType, msg: getChatApplicationUpdateTextAndIcon(latestMsg?.applicationDetails, applicant).title };
    }

    return { msgType: latestMsg.msgType, msg: latestMsg.msg };
}

export const getChatApplicationUpdateTextAndIcon = (applicationDetails: ChatMessageApplicantDetails, applicant: any) => {
    let title = '';
    let icon = null;
    let updateType = applicationDetails.updateType;

    if (updateType === ChatApplicationUpdateType.APPLIED) {
        title = `"${applicant.firstName}" ${applicant.gender === 'FEMALE' ? t('applied_for_job_female') : t('applied_for_job')}`;
        icon = iconCheckGreen;
    } else if (updateType === ChatApplicationUpdateType.VIEWED) {
        title = `${t('you_viewed_1')} ${applicant.firstName} ${t('you_viewed_2')}`;
        icon = iconCheckGreen;
    } else if (updateType === ChatApplicationUpdateType.NOT_SELECTED) {
        title = `${t('wasnt_selected_1')} ${applicant.firstName} ${t('wasnt_selected_2')}`
        icon = iconCloseFilled;
    } else if (updateType === ChatApplicationUpdateType.SHORT_LISTED) {
        title = `${t('you_shortlisted_1')} ${applicant.firstName} ${t('you_shortlisted_2')}`;
        icon = iconFire;
    } else if (updateType === ChatApplicationUpdateType.UNLOCKED) {
        title = t('partner_unlocked_applicant', { value: applicant.firstName });
        icon = iconFire
    } else if (updateType === ChatApplicationUpdateType.JOB_CLOSED) {
        title = t('job_status_closed_onhover')
    } else if (updateType === ChatApplicationUpdateType.INVITATION_SENT) {
        title = t(applicant.gender === 'FEMALE' ? 'invitation_received_female' : 'invitation_received', { value: applicant.firstName });
        icon = iconChatMessages;

    } else if (updateType === ChatApplicationUpdateType.INVITATION_RESPONSE) {
        if (applicationDetails.invitationResponse === PartnerInvitationResponseType.ACCEPTED) {
            title = t(applicant.gender === 'FEMALE' ? 'applicant_job_interested_female' : 'applicant_job_interested', { value: applicant.firstName });
            icon = iconCheckGreen;
        } else if (applicationDetails.invitationResponse === PartnerInvitationResponseType.NEED_MORE_INFO) {
            title = t(applicant.gender === 'FEMALE' ? 'applicant_job_need_more_details_female' : 'applicant_job_need_more_details', { value: applicant.firstName });
            icon = iconHelp;
        } else {
            const rejectionReason = t(`applicant_job_rejection_${applicationDetails.invitationRejectionReason}`);
            const rejectionReasonTitle = t('applicant_job_not_interested', { value: applicant.firstName });
            title = `${rejectionReasonTitle} "${rejectionReason}"`;
            icon = iconCloseFilled;
        }
    }

    return { title, icon };
}

export const generateChatDataForPma = (applicant: ApplicantListItem, rtmToken: string, chatId: string) => {
    return {
        chatId: chatId,
        worker: {
            id: applicant.externalId || applicant.id,
            firstName: applicant.firstName,
            lastName: applicant.lastName,
            mobile: applicant.mobile,
            email: applicant.email,
            age: applicant.age,
            nationality: applicant.nationality,
            bio: applicant.bio,
            city: applicant.city,
            address: applicant.district,
            profileImageUrl: applicant.profileImage || applicant.profileImageUrl,
            lastActiveAt: applicant.lastUpdateAt || applicant.lastActiveAt,
            gender: applicant.gender
        },
        latestMsg: applicant.lastMessage ? {
            id: applicant.lastMessage.id,
            msgType: applicant.lastMessage.msgType,
            senderType: applicant.lastMessage.senderType,
            msg: applicant.lastMessage.msg,
            fileUrl: applicant.lastMessage?.fileUrl,
            createdAt: applicant.lastMessage?.createdAt,
            seenByPartnerAt: applicant.lastMessage?.seenByPartnerAt,
            seenByWorkerAt: applicant.lastMessage?.seenByWorkerAt,
            applicationDetails: null
        } : null,
        newMessagesCount: applicant.newUpdatesCount || 0,
        rtmToken: rtmToken
    }
}


export const convertLookupResultsToDropdownOptions = (items: any, key: string, lang: string) => {
    const options = [];

    if (items.length > 0) {

        items.map(r => r && options.push({ value: r.objectID, label: r?.[key]?.[lang] }))

        const sortedOptions = sortArrayByLabel(options, 'label');

        return sortedOptions;
    }

    return options;
}

export const getNow = () => {
    return Math.ceil(Date.now() / 1000);
}

export const prepareChatMsgPayload = (id: number, text: string, chatId: string, msgType: number, fileUrl: string, senderType: number) => {
    return {
        id: id,
        msgType: msgType,
        senderType: senderType,
        msg: text,
        fileUrl: fileUrl,
        createdAt: getNow(),
        createdDate: fromUnixTimestamp(getNow(), 'yyyy-MM-dd'),
        chatId,
        seenByPartnerAt: getNow(),
        seenByWorkerAt: null,
        applicationDetails: null
    }
}

export const getLookupByKey = (lookups: any, key: keyof PaLookups, lang: string): { value: string, label: string, description?: string, isVisible?: boolean, isHeadquarter?: boolean }[] => {
    if (!lookups) return;
    return lookups[key]?.map(l => {
        // TODO: sometimes it throws an exception (create job form) id cannot read from null
        return l && {
            value: l.id,
            label: l.content[lang],
            description: l.description?.[lang],
            isVisible: l.isVisible,
            isHeadquarter: l.isHeadquarter
        }
    })
}

export const shouldPartnerActivateAccountFirst = (partner: Partner): boolean => {
    if (!partner) return true;

    if (partner.status === PartnerStatus.UNDER_REVIEW || partner.status === PartnerStatus.REQUIRE_ADDITIONAL_INFO) return true;

    if (partner.currentSubscription === null || partner.currentSubscription.subscriptionStatus !== SubscriptionStatusEnum.ACTIVE) {
        return true;
    }

    return false;
}

export const getInitials = (name: string) => {

    if (!name) return '';

    let names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};


// Validate Saudi National ID
// Return -1 if the id is not correct, 1 for Saudi id OR 2 for Non-Saudis.
export function validateSAID(id: string): number {
    const type = id[0];
    const _idLength = 10;
    const _type1 = '1';
    const _type2 = '2';
    let sum = 0;
    id = id.trim();
    if (isNaN(parseInt(id)) || (id.length !== _idLength) || (type !== _type2 && type !== _type1)) {
        return -1;
    }
    for (let num = 0; num < 10; num++) {
        const digit = Number(id[num]);
        if (num % 2 === 0) {
            const doubled = digit * 2;
            const ZFOdd = `00${doubled}`.slice(-2);
            sum += Number(ZFOdd[0]) + Number(ZFOdd[1]);
        } else {
            sum += digit;
        }
    }
    return (sum % 10 !== 0) ? -1 : Number(type);
}

export const getExactExperienceString = (lang: 'en' | 'ar', exactExperience: { jobTitle: string, isHighlighted: boolean, months?: number, years?: number, specialization?: string }) => {
    if (!exactExperience || !exactExperience.jobTitle) return undefined;
    if (lang === 'en') {
        if (!exactExperience.months && !exactExperience.years) {
            return `Worked as ${exactExperience.jobTitle}`
        }
        return `Worked as ${exactExperience.jobTitle} for ${exactExperience.years ? `${exactExperience.years} ${exactExperience.years > 1 ? 'years' : 'year'}` : `${exactExperience.months} ${exactExperience.months > 1 ? 'months' : 'month'}`}`
    }

    if (!exactExperience.months && !exactExperience.years) {
        return `خبرة ${exactExperience.jobTitle}`
    }
    return `خبرة ${exactExperience.jobTitle} لمدة ${exactExperience.years ? `${exactExperience.years > 1 ? exactExperience.years : ''} ${exactExperience.years > 1 ? 'سنوات' : 'سنة'} ` : `${exactExperience.months} ${exactExperience.months > 1 ? 'اشهر' : 'شهر'} `}`
}

export const handleIntercom = () => window.Intercom("showMessages")

export const getHQLocationsList = (lookups, lang) => {
    const cityLookup = getLookupByKey(lookups, 'cityObjectIdLookup', lang);
    return cityLookup
        ? cityLookup.filter(({ isHeadquarter }) => isHeadquarter).map(({ value, label }) => ({
            value: value.replace('_CITY_ONLY', ''),
            label
        }))
        : [];
}
