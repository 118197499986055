import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customAxiosApi, globalConfig } from "../../config/axios-retry";
import Toast from "../../shared/toast/Toast";
import { SubscriptionPurchase } from "../../utils/interfaces/product/ProductPurchase";
import { getPartnerInfo } from "../partner/partner";
import { FULLTIME_PARTNER_ID_KEY } from "../../utils/constants/constants";

const appKey = 'appPartnerSubscriptions';
const apiBaseUrl = 'pa/ft/partners/subscription-plans';
const apiPrepareRequestPaUrl = 'pa/ft/product/prepare-purchase';
const apiPrepareRequestPmaUrl = 'pma/ft/payment/prepare-purchase';
const apiBankQuotationUrl = 'pa/ft/payment/bank-quotations';
const apiFreeSubscriptionUrl = 'pa/ft/product/subscribe-for-free';

export const getSubscriptions = createAsyncThunk(`${appKey}/getSubscriptions`, async () => {
    const response = await customAxiosApi.get(`${apiBaseUrl}`, globalConfig);
    return response.data;
})

export const preparePurchaseRequest = createAsyncThunk(`${appKey}/preparePurchaseRequest`, async (body: any) => {
    const response = await customAxiosApi.post(`${body.ftPartnerExternalId ? apiPrepareRequestPmaUrl : apiPrepareRequestPaUrl}/${body.planId}`, { ...body }, globalConfig);
    return response.data.data;
})

export const getBankQuotation = createAsyncThunk(`${appKey}/getBankQuotation`, async (body: any) => {
    const response = await customAxiosApi.get(`${apiBankQuotationUrl}/${body.planId}?billingCycle=${body.billingCycle}&ftPartnerExternalId=${localStorage.getItem(FULLTIME_PARTNER_ID_KEY) || body.ftPartnerExternalId}`, globalConfig);
    return response.data.data;
})

export const subscribeFreeSubscription = createAsyncThunk(`${appKey}/subscribeFreeSubscription`, async (body: any, { dispatch }) => {
    const response = await customAxiosApi.post(apiFreeSubscriptionUrl, { ...body }, globalConfig);
    if (response.status === 201) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const proceedPurchase = createAction<SubscriptionPurchase>(`${appKey}/proceedPurchase`)


const initialState = {
    isLoading: true,
    data: [],
    purchaseRequestLoader: true,
    purchaseRequest: null,
    preparePurchaseData: null,
    showQuotationLoader: false,
}

export const appPartnerSubscriptionsSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getSubscriptions.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getSubscriptions.rejected, (state, action) => {
                state.isLoading = false;
                Toast.Error({ content: action.error?.message })
            })
            .addCase(preparePurchaseRequest.fulfilled, (state, action) => {
                state.purchaseRequest = action.payload;
                state.purchaseRequestLoader = false;
            })
            .addCase(preparePurchaseRequest.rejected, (state, action) => {
                state.purchaseRequestLoader = false;
                Toast.Error({ content: action.error?.message })
            })
            .addCase(proceedPurchase, (state, action) => {
                state.preparePurchaseData = action.payload;
            })
            .addCase(getBankQuotation.rejected, (state, action) => {
                Toast.Error({ content: action.error?.message })
            })
            .addCase(subscribeFreeSubscription.rejected, (state, action) => {
                Toast.Error({ content: action.error?.message })
            })
    }
})

export default appPartnerSubscriptionsSlice.reducer
