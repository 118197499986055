import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import axios from '../../config/axios';
import Toast from '../../shared/toast/Toast';
import { FileUploadSaveSubmission } from '../../utils/interfaces/fileupload/FileUpload';
import { getPartnerInfo } from '../partner/partner';

const appKey = 'appGenericFileUploader'
const apiModuleUrl = 'pa/ft/submissions/upload-link';
const getRequirementListAPIUrl = 'pa/ft/requirements/list';
const saveSubmissionAPIUrl = 'pa/ft/submissions/save';
const deleteSubmissionAPIUrl = 'pa/ft/submissions/delete-place-image';
const apiStaticUploadUrl = 'website/ft/workers/worker-hired-submissions/upload-link';


export const getRequirementList = createAsyncThunk(`${appKey}/getRequirementList`, async (params: any) => {
    const response = await axios.get(`${getRequirementListAPIUrl}?${params}`);
    return response.data.data;
})

export const generateUploaderLink = createAsyncThunk(`${appKey}/generateUploaderLink`, async (body: any) => {
    const response = await axios.post(apiModuleUrl, body);
    return response.data.data;
})

export const generateStaticUploaderLink = createAsyncThunk(`${appKey}/generateStaticUploaderLink`, async (body: any) => {
    const response = await axios.post(apiStaticUploadUrl, body);
    return response.data.data;
})

export const uploadFile = createAsyncThunk(`${appKey}/generateUploaderLink`, async (model: any) => {
    try {
        const respo = await fetch(model.signedUrl, {
            method: 'PUT',
            body: model.file
        });
        return true;
    } catch (err) {
        const e: any = err;
        Toast.Error({ content: t("invalid_image") });
        return false;
    }

})

export const saveFileSubmission = createAsyncThunk(`${appKey}/saveFileSubmission`, async (body: FileUploadSaveSubmission, { dispatch }) => {
    const response = await axios.post(saveSubmissionAPIUrl, body);
    if (response.status === 201) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const deleteFileSubmission = createAsyncThunk(`${appKey}/deleteFileSubmission`, async (body: any, { dispatch }) => {
    const response = await axios.post(deleteSubmissionAPIUrl, { fileUrl: body.url });
    return response.data.data;
})

export const appGenericFileUploaderSlice = createSlice({
    name: appKey,
    initialState: {
        data: {},
        total: 1,
        params: {},
        allData: [],
        profile: null,
        requirementList: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(generateUploaderLink.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            .addCase(generateUploaderLink.rejected, (state, action) => {
                state.data = {};
                Toast.Error({ content: t("invalid_image") });
            })
            .addCase(getRequirementList.fulfilled, (state, action) => {
                state.requirementList = action.payload
            })
            .addCase(saveFileSubmission.fulfilled, (state, action) => {
                Toast.Success({ content: t('file_uploaded_successfully') })
            })
            .addCase(generateStaticUploaderLink.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            .addCase(generateStaticUploaderLink.rejected, (state, action) => {
                state.data = {};
                Toast.Error({ content: t("invalid_image") });
            })
    }
})

export default appGenericFileUploaderSlice.reducer
