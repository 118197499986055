import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from "i18next";
import axios from '../../config/axios';
import Toast from '../../shared/toast/Toast';
import { CACHED_APPLICANTS_KEY } from '../../utils/constants/constants';
import { ApplicantTabEnum } from '../../utils/enums/ApplicantTabEnum';
import { ApplicationStatusEnum } from '../../utils/enums/ApplicationStatus';
import { ApplicantListItem } from '../../utils/interfaces/applicants/ApplicantListItem';
import { ApplicantsInitialState } from '../../utils/interfaces/applicants/Applicants.store';
import { ApplicantsCounts } from '../../utils/interfaces/applicants/ApplicantsCounts';
import { ApplicantsListRequest } from '../../utils/interfaces/applicants/ApplicantsListRequest';
import { ApplicantsListResponse } from '../../utils/interfaces/applicants/ApplicantsListResponse';
import { CachedApplicantItem } from '../../utils/interfaces/applicants/CachedApplicantItem';
import { UpdateApplicantsData } from '../../utils/interfaces/applicants/UpdateApplicantsData';
import { ResponseResult } from '../../utils/interfaces/http/ResponseResult';

const appKey = 'appApplicants'
const apiModuleUrl = 'pa/ft/job-applications';

export const getRecommendations = createAsyncThunk(`${appKey}/getRecommendations`, async (data: ApplicantsListRequest, { dispatch }): Promise<any> => {
    const filters = data.filters;
    const skip = 0;
    const jobId = data.jobId;

    const recommendedQueryParams = { returnRecommendations: true, statuses: filters?.statuses, sortBy: filters?.sortBy, skip, limit: 20 } as any; delete recommendedQueryParams.filters;
    const recommendedResponse = await axios.get<ResponseResult<ApplicantsListResponse>>(`${apiModuleUrl}/${jobId}/list`, { params: recommendedQueryParams });
    return recommendedResponse.data.data;
});

export const getApplicantsCount = createAsyncThunk(`${appKey}/getApplicantsCount`, async (data: ApplicantsListRequest, { dispatch }): Promise<{ total: number, lookupsData: any }> => {

    const filters = data.filters;
    const skip = filters.skip;
    const jobId = data.jobId;

    const queryParams = { ...filters, skip } as any; delete queryParams.filters;
    const response = await axios.get<ResponseResult<ApplicantsListResponse>>(`${apiModuleUrl}/${jobId}/list`, { params: queryParams });
    const applicantsData = response.data.data;

    const total = filters?.statuses?.includes(ApplicationStatusEnum.SHORT_LISTED) ? applicantsData.shortListedCount : filters?.statuses?.includes(ApplicationStatusEnum.NOT_SELECTED) ? applicantsData?.notSelectedCount : applicantsData?.pendingCount;

    return { total, lookupsData: applicantsData?.lookupsData };
});


export const getApplicantsList = createAsyncThunk(`${appKey}/getApplicantsList`, async (data: ApplicantsListRequest, { dispatch }): Promise<ApplicantsListResponse> => {
    const filters = data.filters;
    const skip = filters.skip;
    const jobId = data.jobId;

    const queryParams = { ...filters, skip } as any; delete queryParams.filters;
    const response = await axios.get<ResponseResult<ApplicantsListResponse>>(`${apiModuleUrl}/${jobId}/list`, { params: queryParams });
    const applicantsData = response.data.data;

    const tabsCounts: ApplicantsCounts = { pendingCount: applicantsData?.pendingCount, shortListedCount: applicantsData?.shortListedCount, notSelectedCount: applicantsData?.notSelectedCount };
    const total = filters?.statuses?.includes(ApplicationStatusEnum.SHORT_LISTED) ? applicantsData.shortListedCount : filters?.statuses?.includes(ApplicationStatusEnum.NOT_SELECTED) ? applicantsData?.notSelectedCount : applicantsData?.pendingCount;

    if (applicantsData?.applicants.length > 0) {
        dispatch(clearRecommendationsList(jobId))
    }

    return {
        ...applicantsData,
        total,
        totalCountOfAllTabs: applicantsData?.total,
        ...tabsCounts
    };
});

export const performActionOnApplicants = createAsyncThunk(`${appKey}/performActionOnApplicants`, async (updatedApplicantsData: UpdateApplicantsData) => {
    const response = await axios.put(`${apiModuleUrl}/update/status`, updatedApplicantsData);
    return { ...response.data.data, status: updatedApplicantsData.status };
});


export const unlockApplicantAttempt = createAsyncThunk(`${appKey}/unlockApplicantAttempt`, async () => {
    const response = await axios.post(`pa/ft/partners/unlock-applicant-attempt`, {});
    return response.data.data;
});

export const inviteApplicant = createAsyncThunk(`${appKey}/inviteApplicant`, async (applicantId: string) => {
    const response = await axios.post(`${apiModuleUrl}/${applicantId}/invite`, {});
    return response.data.data;
});

export const setIsLoadingList = createAction<boolean>(`${appKey}/setIsLoadingList`);
export const updateSelectedApplicant = createAction<ApplicantListItem>(`${appKey}/updateSelectedApplicant`);
export const resetApplicantsPagination = createAction<boolean>(`${appKey}/resetApplicantsPagination`);
export const updateSelectedList = createAction<ApplicantTabEnum>(`${appKey}/updateSelectedList`);
export const refetchApplicantsList = createAction<boolean>(`${appKey}/refetchApplicantsList`);
export const refetchJobInformation = createAction<boolean>(`${appKey}/refetchJobInformation`);
export const updateRecentlyViewedApplicants = createAction<string[]>(`${appKey}/updateRecentlyViewedApplicants`);
export const revealedContactInfoLocally = createAction<boolean>(`${appKey}/revealedContactInfoLocally`);
export const showMobileDetailsViewVisiblity = createAction<boolean>(`${appKey}/showMobileDetailsViewVisiblity`);
export const getApplicantRankInPage = createAction<number>(`${appKey}/getApplicantRankInPage`);
export const refetchRecommendationList = createAction<any>(`${appKey}/refetchRecommendationList`);
export const setIsRecommendationLoadingList = createAction<boolean>(`${appKey}/setIsRecommendationLoadingList`);

export const setCachedApplicantsData = createAction<string>(`${appKey}/setCachedApplicantsData`);
export const hideApplicantProfile = createAction<boolean>(`${appKey}/hideApplicantProfile`);
export const setIsLoadingMore = createAction<boolean>(`${appKey}/setIsLoadingMore`);
export const clearRecommendationsList = createAction<any>(`${appKey}/clearRecommendationsList`)
export const resetApplicantsList = createAction<boolean>(`${appKey}/resetApplicantsList`)
export const setJustViewedChats = createAction<any>(`${appKey}/setJustViewedChats`);
export const resetApplicantNewStatus = createAction<boolean>(`${appKey}/resetApplicantNewStatus`);
export const resetApplicantsLookupData = createAction<boolean>(`${appKey}/resetApplicantsLookupData`);
export const setVisibleChatButton = createAction<boolean>(`${appKey}/setVisibleChatButton`);
export const setIsRecommendationsFetched = createAction<boolean>(`${appKey}/setIsRecommendationsFetched`);
export const updateIsRecommending = createAction<boolean>(`${appKey}/updateIsRecommending`);

const initialState: ApplicantsInitialState = {
    isLoadingList: true,
    isLoadingAction: false,
    applicantsList: [],
    recommendationList: [],
    applicantsCount: 0,
    totalCountOfAllTabs: 0,
    selectedList: ApplicantTabEnum.PENDING,
    cachedApplicants: [],
    currentPage: 1,
    retrievedPages: [],
    needToRefetchApplicantsList: false,
    needToRefetchJobInformation: false,
    selectedApplicantDetails: null,
    showMobileDetailsView: false,
    tabsCounts: {},
    selectedApplicantContactInfo: null,
    recentlyViewedApplicants: [],
    isRecommendationLoadingList: false,
    needToFetchRecommendationList: false,
    hideApplicantCV: false,
    fetchingApplicants: false,
    justViewedChats: [],
    visibleChatButton: false,
    isRecommendationsFetched: false,
    isRecommending: false
}

export const appApplicantsSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(setIsLoadingList, (state, action) => {
                state.isLoadingList = action.payload;
            })
            .addCase(setIsLoadingMore, (state, action) => {
                state.fetchingApplicants = action.payload;
            })
            .addCase(getRecommendations.pending, (state, action) => {
                state.isRecommendationLoadingList = true;
                state.showUpdateJobCTA = undefined;
            })
            .addCase(getRecommendations.fulfilled, (state, action) => {
                const resData: ApplicantsListResponse = action.payload;
                state.recommendationList = resData?.applicants;
                state.isRecommendationLoadingList = false;
                state.recommendedViewsRemaining = resData?.viewsRemaining;
                if (resData?.applicants.length > 0) {
                    state.isRecommendationsFetched = true;
                    state.isRecommendationLoadingList = false;
                    state.showUpdateJobCTA = undefined;
                } else {
                    state.showUpdateJobCTA = state.applicantsList.length === 0;
                }
            })
            .addCase(getRecommendations.rejected, (state, action) => {
                state.isRecommendationLoadingList = false;
                state.showUpdateJobCTA = undefined;
            })
            .addCase(getApplicantsList.fulfilled, (state, action) => {
                const resData: ApplicantsListResponse = action.payload;
                if (state.revealContactInfo) {
                    const applicantId = window.location.hash.split('?page')[0].split('/')[window.location.hash.split('?page')[0].split('/').length - 1];
                    const selectedApplicant = action.payload.applicants.find(x => x.externalId === applicantId);
                    if (selectedApplicant) {
                        state.selectedApplicantContactInfo = {
                            externalId: selectedApplicant.externalId,
                            email: selectedApplicant.email,
                            mobile: selectedApplicant.mobile
                        }
                    }
                } else {
                    state.selectedApplicantContactInfo = null
                }
                state.isLoadingList = false;
                state.fetchingApplicants = false;
                state.applicantsList = resData?.applicants;
                state.lookupsData = resData?.lookupsData;
                state.viewsRemaining = resData?.viewsRemaining;
                state.applicantsCount = resData?.total;
                state.totalCountOfAllTabs = resData?.totalCountOfAllTabs;
                state.currentPage = resData?.currentPage;
                state.retrievedPages = [...state.retrievedPages, resData?.currentPage];
                // state.selectedApplicantDetails = null;
                state.tabsCounts = { pendingCount: resData?.pendingCount, shortListedCount: resData?.shortListedCount, notSelectedCount: resData?.notSelectedCount };
            })
            .addCase(getApplicantsList.rejected, (state, action) => {
                state.isLoadingList = false;
                Toast.Error({ content: action.error?.message })
            })
            .addCase(performActionOnApplicants.pending, (state, action) => {
                // state.isLoadingList = true;
                state.isLoadingAction = true;
                // state.applicantNewStatus = null;
            })
            .addCase(performActionOnApplicants.fulfilled, (state, action) => {
                state.isLoadingAction = false;
                Toast.Success({ content: t(`applicant_updated_${action?.payload?.status || ''}`) });
                // state.applicantNewStatus = action?.payload?.status;
            })
            .addCase(performActionOnApplicants.rejected, (state, action) => {
                state.isLoadingAction = false;
                Toast.Error({ content: action.error?.message })
            })
            .addCase(updateSelectedApplicant, (state, action) => {
                state.selectedApplicantDetails = action.payload;
            })
            .addCase(showMobileDetailsViewVisiblity, (state, action) => {
                state.showMobileDetailsView = action.payload;
            })
            .addCase(updateSelectedList, (state, action) => {
                state.selectedList = action.payload;
            })
            .addCase(resetApplicantsPagination, (state, action) => {
                state.applicantsList = [];
                state.applicantsCount = 0;
                state.totalCountOfAllTabs = 0;
                state.currentPage = 1;
                state.retrievedPages = [];
                state.selectedApplicantDetails = null;
                state.tabsCounts = {
                    notSelectedCount: 0,
                    pendingCount: 0,
                    shortListedCount: 0
                }
            })
            .addCase(refetchApplicantsList, (state, action) => {
                state.needToRefetchApplicantsList = action.payload;
            })
            .addCase(refetchJobInformation, (state, action) => {
                state.needToRefetchJobInformation = action.payload;
            })
            .addCase(revealedContactInfoLocally, (state, action) => {
                state.revealContactInfo = action.payload;
            })
            .addCase(updateRecentlyViewedApplicants, (state, action) => {
                state.recentlyViewedApplicants = action.payload;
            })
            .addCase(getApplicantRankInPage, (state, action) => {
                state.applicantRankInPage = action.payload >= 0 ? Math.abs(action.payload + 1) : 1;
            })
            .addCase(refetchRecommendationList, (state, action) => {
                state.needToFetchRecommendationList = action.payload
            })
            .addCase(setIsRecommendationLoadingList, (state, action) => {
                state.isRecommendationLoadingList = action.payload;
            })
            .addCase(setCachedApplicantsData, (state, action) => {

                const cacheKey = action.payload;

                let applicantsFromLSCache: CachedApplicantItem[] = localStorage.getItem(CACHED_APPLICANTS_KEY) ? JSON.parse(localStorage.getItem(CACHED_APPLICANTS_KEY)) : [];
                const neededData = applicantsFromLSCache.find(ap => ap.key === cacheKey);

                state.applicantsList = neededData?.data?.applicants;
                state.applicantsCount = neededData?.data?.total;
            })
            .addCase(hideApplicantProfile, (state, action) => {
                state.hideApplicantCV = action.payload;
            })
            .addCase(clearRecommendationsList, (state, action) => {
                state.recommendationList = [];
            })
            .addCase(resetApplicantsList, (state, action) => {
                state.isResetApplicantsList = true
            })
            .addCase(setJustViewedChats, (state, action) => {
                state.justViewedChats = action.payload
            })
            .addCase(resetApplicantNewStatus, (state, action) => {
                state.applicantNewStatus = undefined
            })
            .addCase(inviteApplicant.fulfilled, (state, action) => {
                Toast.Success({ content: t(`applicant_invited_successfully`) });
            })
            .addCase(inviteApplicant.rejected, (state, action) => {
                Toast.Success({ content: t(`applicant_invited_error`) });
            })
            .addCase(getApplicantsCount.fulfilled, (state, action) => {
                if (action.payload?.lookupsData) {
                    state.lookupsData = action.payload?.lookupsData;
                }
            })
            .addCase(getApplicantsCount.rejected, (state, action) => {
                Toast.Success({ content: action.error?.message });

            })
            .addCase(resetApplicantsLookupData, (state, action) => {
                if (action.payload) {
                    state.lookupsData = undefined;
                }
            })
            .addCase(setVisibleChatButton, (state, action) => {
                state.visibleChatButton = action.payload
            })
            .addCase(setIsRecommendationsFetched, (state, action) => {
                state.isRecommendationsFetched = action.payload
            })
            .addCase(updateIsRecommending, (state, action) => {
                state.isRecommending = action.payload
            })
    }
})

export default appApplicantsSlice.reducer
