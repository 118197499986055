
import { captureMessage } from '@sentry/browser'
import axios, { AxiosInstance } from 'axios'
import { API_TIMEOUT, FULLTIME_PARTNER_ID_KEY, FULLTIME_PARTNER_TOKEN_KEY } from '../utils/constants/constants'
import { logoutUser } from '../utils/helpers/utils'

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-type': 'application/json',
        'accept-language': localStorage.getItem('i18nextLng')?.includes('en') ? 'en' : 'ar',
        'fulltime-partner-id': localStorage.getItem(FULLTIME_PARTNER_ID_KEY),
        'Authorization': localStorage.getItem(FULLTIME_PARTNER_TOKEN_KEY) ? `Bearer ${localStorage.getItem(FULLTIME_PARTNER_TOKEN_KEY)}` : null,
        'app-source': 'PWA'
    },
    timeout: API_TIMEOUT,
    withCredentials: false,
})

axiosInstance.interceptors.request.use(
    (config: any) => {
        if (!config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(FULLTIME_PARTNER_TOKEN_KEY)}`
        }
        if (!config.headers[FULLTIME_PARTNER_ID_KEY]) {
            config.headers[FULLTIME_PARTNER_ID_KEY] = localStorage.getItem(FULLTIME_PARTNER_ID_KEY)
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use((response) => response,
    async (error) => {
        const httpStatusCode = error?.response?.status;
        const page = window.location.href;
        const errorString = JSON.stringify({ report: `Received ${httpStatusCode} from backend`, error: { ...error?.response?.data?.error }, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });

        if (httpStatusCode === 401 && !page.includes('email-success')) {
            logoutUser();
        }

        if (httpStatusCode === 400) {
            captureMessage(errorString);
        }

        if (httpStatusCode === 403) {
            setTimeout(() => {
                captureMessage(errorString);
                logoutUser();
            }, 5000);
        }

        return Promise.reject(error?.response?.data?.error)
    });

export default axiosInstance;