// ** I18n Imports
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { APP_VERSION_NUMBER } from "../../utils/constants/constants";

const defaultLang = localStorage.getItem('direction') ? JSON.parse(localStorage.getItem('direction')) ? "ar" : "en" : "ar"
const fileVersioning = APP_VERSION_NUMBER;

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: defaultLang,
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json?v=${fileVersioning}`,
        },
        fallbackLng: "ar",
        preload: ['en'],
        debug: false,
        keySeparator: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        }
    });

export default i18n;
