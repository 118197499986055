import { ThemeConfig } from "../utils/interfaces/theme/Theme"

const themeConfig: ThemeConfig = {
    app: {
        appName: 'Sabbar',
        appLogoImage: require('@src/assets/images/logo/logo.svg').default,
        appLogoImageWhite: require('@src/assets/images/logo/logo-white.svg').default,
        appLogoImageNewAr: require('@src/assets/images/logo/sabbar-new-logo-ar.png'),
        appLogoImageNewEn: require('@src/assets/images/logo/sabbar-new-logo-en.png'),
        defaultLanguage: "ar"
    },
    layout: {
        isRTL: true,
        skin: 'light',
        routerTransition: 'fadeIn',
        type: 'horizontal',
        contentWidth: "full",
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            type: 'floating',
            backgroundColor: 'white'
        },
        footer: {
            type: 'static'
        },
        customizer: false,
        scrollTop: false,
        toastPosition: ' bottom-center'
    }
}

export default themeConfig
