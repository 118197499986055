import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResponseResult } from "../../utils/interfaces/http/ResponseResult";
import axios from "../../config/axios";
import { SendEventToBEInterface } from '../../utils/interfaces/partner/SendEventToBEInterface';

const appKey = 'appEvents'
const apiBaseUrl = 'pa/ft/partners'
const sendEventToBEUrl = `${apiBaseUrl}/send-custom-event`;

export const sendEventToBE = createAsyncThunk(`${appKey}/sendEventToBE`, async (body: SendEventToBEInterface) => {
    try {
        const response = await axios.post<ResponseResult<any>>(`${sendEventToBEUrl}`, body);
        return response.data.data;
    }
    catch (err) {
        console.log(err)
    }
})

const initialState = {
}

export const appEventsSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {
    },
})


export default appEventsSlice.reducer
