import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import axios from "../../config/axios";
import Toast from "../../shared/toast/Toast";
import { ResponseResult } from "../../utils/interfaces/http/ResponseResult";
import { DeeplinkLoginInterface, LoginInterface, ValidateOTPInterface } from "../../utils/interfaces/login/Login";
import { LoginStore } from "../../utils/interfaces/login/Login.store";
import { ValidateOTPResponse } from "../../utils/interfaces/login/LoginResponse";

const appKey = 'appLogin'
const apiModuleUrl = 'ft/auth/otp';
const validateOTPUrl = 'ft/auth/validate-otp';
const deeplinkUrl = 'ft/auth/deeplink';
const deleteWorkerUrl = 'gma/ft/workers/confirm-account-deletion';
const validatePartnerAPIUrl = 'ft/auth/validate-registration'

export const authOTP = createAsyncThunk(`${appKey}/login`, async (loginBody: LoginInterface) => {
    const response = await axios.post(`${apiModuleUrl}`, loginBody);
    return response.data.data;
})

export const validateOTP = createAsyncThunk(`${appKey}/validateOTP`, async (otpBody: ValidateOTPInterface) => {
    const response = await axios.post<ResponseResult<ValidateOTPResponse>>(`${validateOTPUrl}`, { mobile: otpBody.mobile, role: otpBody.role, code: otpBody.code });
    return response.data.data;
})

export const deeplink = createAsyncThunk(`${appKey}/deeplink`, async (loginBody: DeeplinkLoginInterface) => {
    const response = await axios.post(`${deeplinkUrl}`, loginBody);
    return response.data.data;
})

export const deleteWorker = createAsyncThunk(`${appKey}/deleteWorker`, async (body: { code: string, accountDeletionReasonId: string }) => {
    const response = await axios.post(`${deleteWorkerUrl}`, { code: body.code, accountDeletionReasonId: body.accountDeletionReasonId });
    return response.data.data;
})

export const validatePartner = createAsyncThunk(`${appKey}/validatePartner`, async (body: { email, mobile, role }, { dispatch }) => {
    const response = await axios.post<ResponseResult<any>>(`${validatePartnerAPIUrl}`, { ...body });
    return response.data.data;
})

export const clearApiErrorMessages = createAction<boolean>(`${appKey}/clearApiErrorMessages`);
export const clearApiMobileEmailErrorMessages = createAction<any>(`${appKey}/clearApiMobileEmailErrorMessages`);

const initialState: LoginStore = {
    isLoading: true,
    data: null,
    otpData: null,
    apiErrorMessage: null,
    mobileErrorMessage: null,
    mobileValidationErrorMessage: null,
    emailErrorMessage: null,
    userMobileNumber: null
}

export const appLoginSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {
        handleLogout: state => {
            const appLang = localStorage.getItem('i18nextLng');
            const appDirection = localStorage.getItem('direction');

            localStorage.clear();

            localStorage.setItem('i18nextLng', appLang);
            localStorage.setItem('direction', appDirection);
            window.Intercom('shutdown');
            window.location.href = '/#/login';
            window.location.reload();
        }
    },
    extraReducers: builder => {
        builder
            .addCase(authOTP.fulfilled, (state, action) => {
                state.data = action.payload;
                state.userMobileNumber = null;
            })
            .addCase(authOTP.rejected, (state, action) => {
                let res: any = action?.error?.message;

                state.userMobileNumber = action.meta?.arg?.mobile?.substring(3);

                state.emailErrorMessage = action?.error?.code === "FT-006" ? res : null;
                state.mobileErrorMessage = action?.error?.code === "FT-007" ? res : null;
                if (res === 'Invalid Request') {
                    state.apiErrorMessage = t('validations_invalid_mobile')
                } else {
                    state.apiErrorMessage = res || t('ERR_BAD_REQUEST');
                }
            })
            .addCase(validateOTP.fulfilled, (state, action) => {
                state.otpData = action.payload;
            })
            .addCase(validateOTP.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message || t('ERR_BAD_REQUEST') })
            })
            .addCase(deeplink.fulfilled, (state, action) => {
                state.otpData = action.payload;
            })
            .addCase(deeplink.rejected, (state, action) => {
                let errorMessage = action?.error?.message || t('ERR_BAD_REQUEST');
                if (errorMessage === 'Unauthorized') errorMessage = t('unauthorized_deeplink')
                Toast.Error({ content: errorMessage })
            })
            .addCase(deleteWorker.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message || t('ERR_BAD_REQUEST') })
            })
            .addCase(clearApiErrorMessages, (state, action) => {
                state.apiErrorMessage = null;
            })
            .addCase(clearApiMobileEmailErrorMessages, (state, action) => {
                state.apiErrorMessage = null;
                if (action.payload.isClearEmailMessage) {
                    state.emailErrorMessage = null;
                }
                if (action.payload.isClearMobileMessage) {
                    state.mobileErrorMessage = null;
                    state.mobileValidationErrorMessage = null
                }
            })
            .addCase(validatePartner.fulfilled, (state, action) => {
                state.mobileValidationErrorMessage = null;
                if (action.meta.arg.email) {
                    state.emailErrorMessage = !action.payload.isEmailValid ? t('email_already_registered') : undefined;
                }
                if (action.meta.arg.mobile) {
                    state.mobileErrorMessage = !action.payload.isMobileValid ? t('mobile_already_registered') : undefined;
                }
            })
            .addCase(validatePartner.rejected, (state, action) => {
                if (action.meta.arg.mobile !== '') {
                    state.mobileValidationErrorMessage = t('validations_invalid_mobile');
                }
            })
    }
})

export const { handleLogout } = appLoginSlice.actions

export default appLoginSlice.reducer
