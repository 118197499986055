import layout from './layout/layout';
import fulltimeJob from './jobs/job';
import login from './auth/login';
import partnerProfile from './partner/partner';
import fileupload from './fileupload/fileupload';
import payment from './payment/payment';
import applicants from './applicants/applicants';
import subscriptions from './subscriptions/subscriptions';
import externalPartner from './partner/externalPartner';
import chat from './chat/chat-messages'
import events from './events/events'
import partnerUser from './partner/partnerUser'
import workerHiredForm from './worker-hire-form/workerHiredForm'
import pma from './pma/pma';

const appReducer = {
    layout,
    login,
    fulltimeJob,
    partnerProfile,
    fileupload,
    payment,
    applicants,
    subscriptions,
    externalPartner,
    chat,
    events,
    partnerUser,
    workerHiredForm,
    pma
}

export default appReducer