//@ts-nocheck

import { toast } from "react-toastify";
import { ToastContent } from "./Toast";
import { useMobile } from "../../utils/hooks/useMobile";

const ErrorToast: React.FC<{ content: string; title?: string }> = ({
  content,
  title = null,
}) => {
  const isMobile = useMobile()

  const toastConfig = {
    position: isMobile ? "top-center" : "bottom-center",
    autoClose: 3000,
    hideProgressBar: true
  }

  return toast.error(
    <ToastContent type={"error"} content={content} title={title} />,
    toastConfig
  );
}

export default ErrorToast;
