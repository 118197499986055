import { format, formatDistanceToNowStrict, intervalToDuration } from "date-fns";
import { formatDistanceToNow, fromUnixTime } from "date-fns/esm";
import { ar, enUS } from "date-fns/locale";
import { appLanguage } from "../constants/language";

export const formatDate = (date: Date | number, toFormat: string = 'ddMMyyyy', lang: string = appLanguage) => {
    return format(date, toFormat, { locale: lang === "ar" ? ar : enUS })
}

export const fromUnixTimestamp = (timestamp: number, toFormat: string = 'ddMMyyyy', lang: string = appLanguage) => {
    if (timestamp) {
        const date = fromUnixTime(timestamp);
        return formatDate(date, toFormat, lang);
    }
}

export const timeAgo = (timestamp: number, lang: string = appLanguage, shortern: boolean = false) => {
    if (timestamp) {
        let timeString = '';
        const date = fromUnixTime(timestamp);
        const locale = lang === "ar" ? ar : enUS;
        if (shortern) {
            timeString = formatDistanceToNow(date, {
                addSuffix: false,
                locale: {
                    ...locale,
                    formatDistance
                }
            })
        } else {
            timeString = formatDistanceToNowStrict(date, { addSuffix: true, locale: locale })
        }
        return timeString.replace('شهران', 'شهرين');
    }
}

const formatDistanceLocale = {
    lessThanXSeconds: '{{count}}s',
    xSeconds: '{{count}}s',
    halfAMinute: '30s',
    lessThanXMinutes: '{{count}}m',
    xMinutes: '{{count}}m',
    aboutXHours: '{{count}}h',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
    aboutXWeeks: '{{count}}w',
    xWeeks: '{{count}}w',
    aboutXMonths: '{{count}}m',
    xMonths: '{{count}}mo',
    aboutXYears: '{{count}}y',
    xYears: '{{count}}y',
    overXYears: '{{count}}y',
    almostXYears: '{{count}}y',
}

const formatDistance = (token, count, options) => {
    options = options || {}

    const result = formatDistanceLocale[token].replace('{{count}}', count)

    if (options.addSuffix) {
        if (options.comparison > 0) {
            return 'in ' + result
        } else {
            return result + ' ago'
        }
    }

    return result
}


export const getDurationBetweenDates = (from: number, to: number) => {
    const fromDate = fromUnixTimestamp(from, "MM/dd/yyyy");
    const toDate = fromUnixTimestamp(to, "MM/dd/yyyy");

    return intervalToDuration({
        start: new Date(fromDate),
        end: new Date(toDate)
    })
}

export const vannilaGetDurationBetweenDates = (from: Date, to: Date) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(from.getFullYear(), from.getMonth(), from.getDate());
    const utc2 = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export const getDaysBetweenTwoDates = (from: number, to?: number) => {
    const endDate = to ? to : Math.ceil(new Date().getTime() / 1000);
    const startDate = fromUnixTimestamp(from, 'yyyy-MM-dd');
    const now = fromUnixTimestamp(endDate, 'yyyy-MM-dd');
    return vannilaGetDurationBetweenDates(new Date(startDate), new Date(now));
}

export const formattedChatCreationDate = (date: string, t: any) => {

    if (!date) return undefined;

    const returnFormat = 'dd MMM yyyy';

    const daysSinceMessageCreated = vannilaGetDurationBetweenDates(new Date(date), new Date())

    if (daysSinceMessageCreated === 0) {
        return t("today")
    } else if (daysSinceMessageCreated === 1) {
        return t("yesterday")
    } else {
        return formatDate(new Date(date), returnFormat);
    }
}

export const getTotalWorkedExperiences = (items: any, jobTitle: string, t: any) => {

    let totalYears = 0;
    let totalMonths = 0;
    let months = 0;

    const jobExperience = items.filter(x => x.jobTitle === jobTitle);

    if (jobExperience.length > 0) {
        months = jobExperience.reduce((acc, curr) => {
            return acc = acc + curr.months
        }, 0)

        totalYears = Math.floor(months / 12);
        totalMonths = months % 12;
    }

    const labelYears = totalYears > 1 ? t("short_years") : t("short_year")
    const labelMonth = totalMonths > 1 ? t("short_months") : t("short_month")

    if (totalYears === 0 && totalMonths === 0) {
        return undefined
    }

    return `${totalYears > 0 ? `${totalYears} ${labelYears}` : ''} ${totalMonths > 0 ? `${totalMonths} ${labelMonth}` : ''}`

}
