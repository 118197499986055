export const contractTypeIcon =
  require(`@src/assets/images/icons/info-suitcase.svg`).default;

export const activeIcon =
  require(`@src/assets/images/icons/icon-verified.svg`).default;

export const onlineIcon =
  require(`@src/assets/images/icons/online.svg`).default;

export const lockedIcon =
  require(`@src/assets/images/icons/icon-locked.svg`).default;

export const mapIcon =
  require(`@src/assets/images/icons/info-mappin.svg`).default;

export const noVideoCVIcon =
  require(`@src/assets/images/icons/no-video-cv.svg`).default;

export const GreenCheckIcon = ({ size, className = '' }) => {
  const url =
    require("@src/assets/images/icons/icon-green-check-filled.svg").default;

  return <img src={url} alt="green check icon" width={size} height={size} className={className} />;
};

export const companyIcon =
  require("@src/assets/images/icons/icon-company.svg").default;

export const contactInfoIcon =
  require("@src/assets/images/icons/icon-contact-info.svg").default;

export const envIcon =
  require("@src/assets/images/icons/icon-environment.svg").default;

export const confirmUnlockIcon =
  require("@src/assets/images/icons/icon-unlock.svg").default;

export const payToUnlockIcon =
  require("@src/assets/images/icons/icon-pay-to-unlock.svg").default;

export const paymentSuccessfulIcon =
  require(`@src/assets/images/icons/icon-payment-success.png`);

export const paymentFailedIcon =
  require(`@src/assets/images/icons/icon-upset.svg`).default;

export const blockedIcon2 = require(`@src/assets/images/icons/icon-blocked-2.svg`).default;

export const buildingIcon = require("@src/assets/images/icons/icon-building.svg").default;

export const ksaFlagIcon = require("@src/assets/images/icons/icon-ksa-flag.svg").default;

export const warningIcon = require("@src/assets/images/icons/icon-wanring.svg").default;

export const crossMarkIcon = require("@src/assets/images/icons/icon-crossmark.svg").default;

export const creditCardIcon = require("@src/assets/images/icons/icon-credit-cards.svg").default;

export const downloadAppBanner = require("@src/assets/images/pages/download-app-banner.png");

export const appQRCode = require("@src/assets/images/pages/qr-code.png");

export const googlePlayStoreIcon = require("@src/assets/images/pages/google-playstore.svg").default;

export const appStoreIcon = require("@src/assets/images/pages/app-store.svg").default;

export const moneyIconSmall = require("@src/assets/images/icons/money-small.svg").default;

export const hurrayIcon = require("@src/assets/images/icons/icon-hurray.svg").default;

export const orangeGlobeIcon = require("@src/assets/images/icons/globe-orange.svg").default;

export const whatsAppIcon = require("@src/assets/images/icons/WA-logo.svg").default;

export const storeIcon = require("@src/assets/images/icons/store.png");

export const blockedIcon = require("@src/assets/images/icons/icon-blocked.svg").default;

export const bulbIcon = require("@src/assets/images/icons/icon-bulb.svg").default;

export const ministryVerificationIcon = require("@src/assets/images/icons/ministry-verification-icon.png");

export const jobPostConfirmationIcon = require("@src/assets/images/icons/job-post-confirmation-icon.svg").default;

export const orangeAvatar = require("@src/assets/images/icons/orange-avatar.svg").default;

export const verifiedIcon = require("@src/assets/images/icons/verified-orange-icon.svg").default;

export const backLeft = require("@src/assets/images/icons/back-left.png");

export const backRight = require("@src/assets/images/icons/back-right.png");

export const whatsAppIconOrange = require("@src/assets/images/icons/icon-whatsapp.svg").default;

export const sabbarLogoOrange = require('@src/assets/images/logo/sabbar-logo.jpg')

export const invoiceIcon = require("@src/assets/images/icons/invoice-icon.png");

export const noImageIcon = require("@src/assets/images/icons/no-image.png");

export const companyProfileIcon = require("@src/assets/images/icons/office-building.svg").default;

export const jobsIcon = require("@src/assets/images/icons/briefcase.svg").default;

export const buildingIcon2 = require("@src/assets/images/icons/icon-building-2.svg").default;

export const jobSeekerIcon = require("@src/assets/images/icons/icon-job-seeker.svg").default;

export const largeImageSizeIcon = require("@src/assets/images/icons/icon-file-large-size.png");

export const sittingAloneIcon = require("@src/assets/images/icons/icon-sitting-alone.png");

export const infoPurpleIcon = require("@src/assets/images/icons/info-purple.svg").default;

export const promoteIcon = require("@src/assets/images/icons/promote-icon.png");

export const whiteBulbIcon = require("@src/assets/images/icons/icon-light-bulb-white.svg").default;

export const educationLogo = require("@src/assets/images/logo/moe-logo.png");

export const emailOrangeIcon = require("@src/assets/images/icons/email-orange.svg").default;

export const emailSuccessIcon = require("@src/assets/images/icons/email-success.svg").default;

export const filterAdjustmentIcon = require('@src/assets/images/icons/icon-filter-adjustments.svg').default

export const noWorkersFoundImage = require("@src/assets/images/icons/sabbar-searching.svg").default;

export const iconCloseFilled = require('@src/assets/images/icons/icon-close-filled.svg').default;

export const paginationRight = require("@src/assets/images/icons/pagination-right.png");

export const paginationLeft = require("@src/assets/images/icons/pagination-left.png");

export const iconPlay = require("@src/assets/images/icons/icon-play.png");

export const greyRightIcon = require("@src/assets/images/icons/grey-right.png");

export const greyLeftIcon = require("@src/assets/images/icons/grey-left.png");

export const emptyVideoPlaceholder = require("@src/assets/images/icons/empty-placeholder.png");

export const darkClose = require("@src/assets/images/icons/x.svg").default;

export const iconCheckGreen = require("@src/assets/images/icons/icon-check-circle-filled.svg").default;

export const iconCheckDarkGreen = require("@src/assets/images/icons/dark-green-check.svg").default;

export const iconChat = require("@src/assets/images/icons/icon-chat.svg").default;

export const contractIcon = require("@src/assets/images/icons/jp-contract.svg").default;

export const workplaceIcon = require("@src/assets/images/icons/jp-workplace.svg").default;

export const salaryIcon = require("@src/assets/images/icons/jp-salary.svg").default;

export const descriptionIcon = require("@src/assets/images/icons/jp-description.svg").default;

export const locationIcon = require("@src/assets/images/icons/jp-location.svg").default;

export const notFoundImage = require("@src/assets/images/pages/not-found.svg").default;

export const iconApplicantsCardSlidersEn = require("@src/assets/images/pages/job-seeker-cards.gif");

export const iconApplicantsCardSlidersAr = require("@src/assets/images/pages/job-seeker-cards-ar.gif");

export const pendingIcon = require("@src/assets/images/icons/illustration-pending-info.svg").default;

export const pendingGreyIcon = require("@src/assets/images/icons/illustration-grey.svg").default;

export const phoneIcon = require("@src/assets/images/icons/icon-phone.svg").default;

export const jobsIcon2 = require("@src/assets/images/icons/jobs-icon.svg").default;

export const freeJobPostIcon = require("@src/assets/images/icons/icon-free-post.png");

export const jobsGrayIcon = require("@src/assets/images/icons/briefcase-gray.svg").default;

export const companyProfileGrayIcon = require("@src/assets/images/icons/office-building-gray.svg").default;

export const appleIcon = require("@src/assets/images/icons/apple-icon.png");

export const ccMethodIcon = require("@src/assets/images/icons/icon-credit-cards-2.png");

export const applePayMethodIcon = require("@src/assets/images/icons/apple-pay-logo-icon.png");

export const diplomaLogo = require("@src/assets/images/icons/icon-education.svg").default;

export const phoneIconOrange = require("@src/assets/images/icons/icon-phone-orange.svg").default;

export const discountIcon = require("@src/assets/images/icons/icon-discount.svg").default;

export const bankTransferIcon = require("@src/assets/images/icons/icon-bank-payment.svg").default;

export const dollarIcon = require("@src/assets/images/icons/icon-dollar.svg").default;

export const documentIcon = require("@src/assets/images/icons/icon-document.svg").default;

export const alinmaBankLogo = require("@src/assets/images/logo/alinma-bank-logo.svg").default;

export const iconMoney = require("@src/assets/images/icons/icon-money.svg").default;

export const sabbarLogo = require('@src/assets/images/logo/sabbar-logo.svg').default;

export const jobSeekerIcon1 = require('@src/assets/images/icons/icon_jobseeker_1.svg').default;

export const jobSeekerIcon2 = require('@src/assets/images/icons/icon_jobseeker_2.svg').default;

export const jobSeekerIcon3 = require('@src/assets/images/icons/icon_jobseeker_3.svg').default;

export const upArrowOrangeIcon = require('@src/assets/images/icons/icon-up.svg').default;

export const iconChatBlack = require("@src/assets/images/icons/icon-chat-black.svg").default;

export const whatsAppIconGray = require("@src/assets/images/icons/icon-whatsapp-gray.svg").default;

export const historyIconGray = require("@src/assets/images/icons/icon-history.svg").default;

export const creditCardVector = require("@src/assets/images/icons/icon-credit card.svg").default;

export const thumbsUpIcon = require("@src/assets/images/icons/icon-thumbs-up.svg").default;

export const thumbsDownIcon = require("@src/assets/images/icons/icon-thumbs-down.svg").default;

export const chatIcon = require("@src/assets/images/icons/chat-icon.svg").default;

export const cameraIcon = require("@src/assets/images/icons/icon-camera.svg").default;

export const documentIcon2 = require("@src/assets/images/icons/icon-document-2.svg").default;

export const iconMessageRead = require("@src/assets/images/icons/icon-message-read.svg").default;

export const chatIconGray = require("@src/assets/images/icons/chat-icon-gray.svg").default;

export const iconJob = require("@src/assets/images/icons/icon-job.svg").default;

export const iconJobs = require("@src/assets/images/icons/icon-jobs.svg").default;

export const iconMoneyBag = require("@src/assets/images/icons/icon-money-bag.png");

export const iconFire = require("@src/assets/images/icons/icon-fire.png");

export const iconChatMessages = require("@src/assets/images/icons/icon-chat-messages.svg").default;

export const iconHelp = require("@src/assets/images/icons/icon-help.svg").default;

export const recommendedIcon = require("@src/assets/images/icons/icon-recommended.svg").default;

export const iconFireVector = require("@src/assets/images/icons/icon-fire.svg").default;

export const iconNoApplicants = require("@src/assets/images/icons/icon-no-applicants.png");

export const iconNew = require("@src/assets/images/icons/icon-sparkles.svg").default;

export const mtLogo = require("@src/assets/images/logo/ministry-of-tourism-logo.svg").default;

export const iconOrganization = require("@src/assets/images/icons/icon-organization.svg").default;

export const iconAuthourizedPerson = require("@src/assets/images/icons/icon-authourized-person.svg").default;

export const iconVerifiedJob = require("@src/assets/images/icons/icon-verified-job.svg").default;

export const iconAccountActivation = require("@src/assets/images/icons/icon-account-activation.svg").default;

export const iconAccountPendingReview = require("@src/assets/images/icons/icon-account-pending-review.svg").default;

export const logoMHRSD = require("@src/assets/images/icons/mhrsd-logo.svg").default;

export const jobSeekersIcon = require('@src/assets/images/icons/icon-job-seekers.png');

export const blurredCvIcon = require('@src/assets/images/icons/blurred-cv.png');

export const iconFireTGVector = require("@src/assets/images/icons/icon-fire-turquoise.svg").default;

export const iconStudent = require("@src/assets/images/icons/icon-student.svg").default;

export const recommendedIconPurple = require("@src/assets/images/icons/icon-recommended-purple.svg").default;

export const iconArticle = require("@src/assets/images/icons/icon-article.svg").default;

export const iconPlatForm = require("@src/assets/images/icons/icon-platform.svg").default;

export const iconCheckedTG = require("@src/assets/images/icons/icon-check-circle-filled-tg.svg").default;

export const iconLightningBolt = require("@src/assets/images/icons/icon-lightning-bolt.svg").default;

export const iconBriefcaseTG = require("@src/assets/images/icons/info-briefcase-tg.svg").default;

export const iconCurrencyDollarTG = require("@src/assets/images/icons/icon-currency-dollar-tg.svg").default;

export const iconLocationTG = require("@src/assets/images/icons/icon-location-marker-tg.svg").default;

export const recommendationCardsEN = require("@src/assets/images/pages/recommendations-en.gif");

export const recommendationCardsAR = require("@src/assets/images/pages/recommendations-ar.gif");

export const iconNoMessages = require("@src/assets/images/icons/icon-chat-no-message.svg").default;

export const iconTime = require('@src/assets/images/icons/icon-time.svg').default;

export const iconCostEffective = require('@src/assets/images/icons/icon-cost-effective.svg').default;

export const iconDownload2 = require('@src/assets/images/icons/icon-download-2.svg').default;

export const iconLock2 = require('@src/assets/images/icons/icon-lock-2.svg').default;

export const askToUploadIcon = require('@src/assets/images/icons/ask-upload-icon.svg').default;

export const askToUploadIcon2 = require('@src/assets/images/icons/ask-to-upload.png');

