import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/browser';
import defaultAxios from 'axios';
import { t } from 'i18next';
import { LookupsSearchIndexEnum } from 'src/utils/enums/LookupsSearchIndexEnum';
import { isProduction } from 'src/utils/helpers/utils';
import { ApplicantListItem } from 'src/utils/interfaces/applicants/ApplicantListItem';
import { ApplicantsNavParams } from 'src/utils/interfaces/job/ApplicantsNavParams';
import axios from '../../config/axios';
import Toast from '../../shared/toast/Toast';
import { FULLTIME_PARTNER_ID_KEY, FULLTIME_PARTNER_TOKEN_KEY, TYPESENSE_APIKEY, TYPESENSE_CONFIG, TYPESENSE_HOST } from '../../utils/constants/constants';
import { JobStatusEnum } from '../../utils/enums/JobStatus';
import { ResponseResult } from '../../utils/interfaces/http/ResponseResult';
import { FullTimeJob } from '../../utils/interfaces/job/Job';
import { JobInitialState } from '../../utils/interfaces/job/Job.store';
import { FullTimeJobDetails } from '../../utils/interfaces/job/JobDetails';
import { NewJobPost } from '../../utils/interfaces/job/NewJobPost';
import { GetJobListRequest, GetJobRequest, UnlockContactInfoRequest } from '../../utils/interfaces/job/QueryParams';
import { refetchRecommendationList, setIsRecommendationLoadingList, setIsRecommendationsFetched, updateIsRecommending } from '../applicants/applicants';
import { getPartnerInfo } from '../partner/partner';
import { TypesenseOptions } from 'src/utils/interfaces/job/TypesenseOptions';

const appKey = 'appFullTimeJobs'
const apiModuleUrl = 'pa/ft/job-posts/list';
const jobDetailsUrl = 'pa/ft/job-posts';
const jobApplicationsUrl = 'pa/ft/job-applications';
const getProductsUrl = 'pa/ft/product/list';
const postJobUrl = 'pa/ft/job-posts/post-job';
const editJobUrl = 'pa/ft/job-posts/edit-job-post';
const jobTitleUrl = 'generic/ft/job-posts/search-job-titles';
const passPaymentPromptUrl = 'pa/ft/job-posts';
const closeJobUrl = 'pa/ft/job-posts/close-job-post/';
const recommendedSkillsUrl = 'pa/ft/job-posts/list-recommended-skills'

export const getJobList = createAsyncThunk(`${appKey}/getJobList`, async (params: GetJobListRequest) => {
    const response = await axios.get<ResponseResult<FullTimeJob[]>>(`${apiModuleUrl}?partnerId=${params.partnerId}&skip=0&limit=50`);
    return { list: response.data.data, hideLoading: params.hideLoading };
})

export const getJobDetails = createAsyncThunk(`${appKey}/getJobDetails`, async (params: GetJobRequest) => {
    const response = await axios.get<ResponseResult<FullTimeJobDetails>>(`${jobDetailsUrl}/${params.jobId}`);
    return response.data.data;
})

// THIS THUNK IS PURPOSELY FOR KEEP CALLING JOB DETAILS API WITHOUT UPDATING THE STATE.
export const getJobDetailsForRecommendations = createAsyncThunk(`${appKey}/getJobDetailsForRecommendations`, async (params: GetJobRequest) => {
    const response = await axios.get<ResponseResult<FullTimeJobDetails>>(`${jobDetailsUrl}/${params.jobId}`);
    return response.data.data;
})

export const getApplicant = createAsyncThunk(`${appKey}/getApplicant`, async (applicationId: string) => {
    const response = await axios.get<ResponseResult<ApplicantListItem>>(`${jobApplicationsUrl}/${applicationId}/details`);
    return response.data.data;
})

export const getJobSeekersCount = createAsyncThunk(`${appKey}/getJobSeekersCount`, async (filters?: { gender?: string, nationality?: string, location?: any }) => {
    const response = await axios.get<ResponseResult<{ count: number }>>(`${jobApplicationsUrl}/job-seekers-count`, { params: filters });
    return response.data.data;
})

export const addNote = createAsyncThunk(`${appKey}/addNote`, async (body: { applicantId: string, note: string }) => {
    const response = await axios.put<ResponseResult<any>>(`${jobApplicationsUrl}/${body.applicantId}/note`, { note: body.note });
    return response.data.data;
})

export const deleteNote = createAsyncThunk(`${appKey}/delete`, async (applicantId: string) => {
    const response = await axios.delete<ResponseResult<any>>(`${jobApplicationsUrl}/${applicantId}/note`);
    return response.data.data;
})

export const unlockContactInfo = createAsyncThunk(`${appKey}/unlockContactInfo`, async (query: UnlockContactInfoRequest, { dispatch }) => {
    const body = {
        currentPage: query.currentPage,
        applicantRankInPage: query.applicantRankInPage,
        sortMethod: query.sortMethod
    }

    const endPoint = `${jobDetailsUrl}/${query.jobId}/unlock/${query.applicantId}`
    const response = await axios.put<ResponseResult<any>>(endPoint, body);

    if (response.status === 200) {
        dispatch(getPartnerInfo());
        dispatch(getJobDetails({ partnerId: query.partnerId, jobId: query.jobId }))
    }

    return response.data.data;
})

export const updateApplicantLastViewedAt = createAsyncThunk(`${appKey}/updateApplicantLastViewedAt`, async (applicantId: string, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${jobApplicationsUrl}/${applicantId}/last-viewed`);
    return response.data.data;
})

export const getProducts = createAsyncThunk(`${appKey}/getProducts`, async (params: any) => {
    const response = await axios.get(`${getProductsUrl}?jobId=${params.jobId}&vacancies=1`);
    return response.data.data;
})

export const getJobTitles = createAsyncThunk(`${appKey}/getJobTitles`, async (searchKey: string = "") => {
    const response = await axios.get(`${jobTitleUrl}?term=${searchKey}`);
    return response.data.data;
})


// Generic Search Lookup using Typesense
export const searchTypesenseLookup = createAsyncThunk(`${appKey}/getTypesenseLookup`, async (options: TypesenseOptions) => {
    const lang = localStorage.getItem('i18nextLng')?.includes('en') ? 'en' : 'ar';
    const fieldName = options.fieldName;

    const response = await defaultAxios.post(`https://${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${TYPESENSE_APIKEY}`,
        {
            searches: [{
                q: options.searchKey,
                query_by: fieldName,
                highlight_full_fields: fieldName,
                collection: options.collection,
                sort_by: options?.sortBy || '',
                filter_by: options.filters || '',
                page: 1,
                per_page: options.perPage || 15,
                infix: options.infix || 'off',
            }]
        },
        TYPESENSE_CONFIG
    );

    if (response?.data?.results?.[0].code && response?.data?.results?.[0].code !== 200 && response?.data.results?.[0].error) {
        if (isProduction()) {
            Toast.Error({ content: t("typesense_error") });
            const errorString = JSON.stringify({ report: `error in searchTypesenseLookup`, error: response?.data.results?.[0].error, message: response?.data.results?.[0].error, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });
            captureMessage(errorString);
        } else {
            Toast.Error({ content: response?.data.results?.[0].error });
        }
        return [];
    } else {
        if (options.mapResults === false) {
            return response.data?.results?.[0]?.hits;
        } else {
            return response.data?.results?.[0]?.hits?.map(hit => {
                return {
                    ...hit,
                    displayName: hit?.highlight?.[fieldName]?.[lang]?.value?.replace(/mark/g, 'em') || hit?.document?.[fieldName]?.[lang]?.value?.replace(/mark/g, 'em') || hit?.document?.[fieldName]?.[lang]?.replace(/mark/g, 'em'),
                    [fieldName]: hit?.document?.[fieldName],
                    objectID: hit?.document?.id
                }
            });
        }

    }
})

export const createJob = createAsyncThunk(`${appKey}/createJob`, async (body: NewJobPost, { dispatch }) => {
    const response = await axios.post(postJobUrl, body);
    if (response.status === 201) {

        const jobDetails = await dispatch(getJobDetails({ jobId: response.data.data.jobId }))
        setTimeout(async () => {
            if (jobDetails.meta.requestStatus === "fulfilled") {
                dispatch(setIsRecommendationLoadingList(true));
                window.CHECK_RECOMMENDATIONS_INTERVAL = setInterval(() => {
                    dispatch(getJobDetailsForRecommendations({ jobId: response.data.data.jobId }))
                        .then(res => {
                            const result: any = res.payload;
                            if (result && !result.isRecommending) {
                                dispatch(refetchRecommendationList(true))
                                clearInterval(CHECK_RECOMMENDATIONS_INTERVAL);
                                dispatch(updateIsRecommending(false));
                            }
                        })
                }, 5000)
            }
        }, 3000);

        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const updateJob = createAsyncThunk(`${appKey}/updateJob`, async (body: NewJobPost, { dispatch }) => {
    const response = await axios.post(`${editJobUrl}/${body.jobId}`, body);
    if (response.status === 201) {

        dispatch(setIsRecommendationLoadingList(true));
        window.CHECK_RECOMMENDATIONS_INTERVAL = setInterval(() => {
            dispatch(getJobDetailsForRecommendations({ jobId: body.jobId }))
                .then(res => {
                    const result: any = res.payload;
                    if (result && !result.isRecommending) {
                        dispatch(setIsRecommendationsFetched(false));
                        dispatch(setIsRecommendationLoadingList(false));
                        dispatch(refetchRecommendationList(true));
                        clearInterval(CHECK_RECOMMENDATIONS_INTERVAL);
                        setInterval(() => {
                            dispatch(updateIsRecommending(false));
                        }, 1500)
                    }
                })
        }, 3000)


        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const closeJob = createAsyncThunk(`${appKey}/closeJob`, async (body: { id: string, partnerId: string }, { dispatch }) => {
    const response = await axios.put(`${closeJobUrl}${body.id}`);
    if (response.status === 200) {
        dispatch(getJobList({ partnerId: body.partnerId }))
    }
    return response.data.data;
})

export const passPaymentPrompt = createAsyncThunk(`${appKey}/passPaymentPrompt`, async (body: { jobId: string, reason?: string }) => {
    const response = await axios.put(`${passPaymentPromptUrl}/${body.jobId}/pass-payment-prompt`, { reason: body.reason });
    return response.data.data;
})

export const updateApplicantsNavParams = createAction<ApplicantsNavParams>(`${appKey}/updateApplicantsNavParams`);


export const getRecommendedSkills = createAsyncThunk(`${appKey}/getRecommendedSkills`, async (jobTitleId: string) => {
    const response = await axios.get<ResponseResult<any>>(`${recommendedSkillsUrl}?jobTitleId=${jobTitleId}`);
    return response.data.data;
})

export const getTypesenseObjectsByIDs = createAsyncThunk(`${appKey}/getTypesenseObjectsByIDs`, async (object: { index: LookupsSearchIndexEnum, ids: string[] }) => {
    const requests = [];
    const lang = localStorage.getItem('i18nextLng')?.includes('en') ? 'en' : 'ar';
    let fieldName = 'name';

    if (object.ids.length === 0) {
        return requests;
    }

    object.ids.map(id => requests.push(
        {
            q: id,
            query_by: 'objectID',
            highlight_full_fields: '',
            collection: object.index,
            sort_by: '',
            filter_by: '',
            page: 1,
            per_page: 15,
        }
    ));

    const response = await defaultAxios.post(`https://${TYPESENSE_HOST}/multi_search?x-typesense-api-key=${TYPESENSE_APIKEY}&limit_multi_searches=200`,
        { searches: requests },
        TYPESENSE_CONFIG
    );

    if (response?.data?.results?.[0].code && response?.data?.results?.[0].code !== 200 && response?.data.results?.[0].error) {
        if (isProduction()) {
            // Toast.Error({ content: t("typesense_error") });
            const errorString = JSON.stringify({ report: `error in getTypesenseObjectsByIDs`, error: response?.data.results?.[0].error, message: response?.data.results?.[0].error, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });
            captureMessage(errorString);
        } else {
            // Toast.Error({ content: response?.data.results?.[0].error });
        }
        return [];
    }

    return response.data?.results?.map(hitItem => {
        return {
            // ...hitItem,
            name: hitItem?.hits?.[0]?.document?.name || hitItem?.hits?.[0]?.document?.searchableText,
            searchableText: hitItem?.hits?.[0]?.document?.name || hitItem?.hits?.[0]?.document?.searchableText,
            objectID: hitItem?.hits?.[0]?.document?.objectID
        }
    });
})

const initialState: JobInitialState = {
    jobList: [],
    jobDetails: null,
    applications: [],
    totalApplicants: 0,
    isApplicationLoading: true,
    isLoading: false,
    isUnlockLoading: false,
    applicantDetails: null,
    isProductListLoading: false,
    productList: [],
    lastJobDraft: null,
    matches: null,
    jobTitles: [],
    jobSeekersCount: 0,
    applicantsNavParams: {
        from: 'JobsList',
        scrollPosition: 0,
        currentPage: 0
    },
    shortListedCount: 0,
    pendingCount: 0,
    notSelectedCount: 0,
    recommendedSkills: []
}
export const appFullTimeJobListSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {
        handleClearApplicantsList: (state) => {
            state.applications = []
        },
        handleClearLastJobDraft: (state) => {
            state.lastJobDraft = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getJobList.pending, (state, action) => {
                state.isLoading = action?.meta?.arg?.hideLoading ? false : true;
                state.jobDetails = null
            })
            .addCase(getJobList.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload?.list) {
                    const sortedByDate = action.payload.list.sort((a, b) => b.issueDate - a.issueDate);
                    const jobList = [...sortedByDate];
                    const activeJobList = jobList.filter(x => x.status === JobStatusEnum.OPEN);
                    const nonRejectedJobList = jobList.filter(x => x.status !== JobStatusEnum.OPEN && x.status !== JobStatusEnum.REJECTED);
                    const rejectedJobList = jobList.filter(x => x.status === JobStatusEnum.REJECTED);
                    state.jobList = [...activeJobList, ...nonRejectedJobList, ...rejectedJobList];
                }
            })
            .addCase(getJobList.rejected, (state, action) => {
                state.isLoading = false;
                setTimeout(() => {
                    const token = localStorage.getItem(FULLTIME_PARTNER_TOKEN_KEY);
                    if (!token) {
                        Toast.Error({ content: t("session_ended") })
                        localStorage.clear();
                        window.location.href = '/#/login';
                    }
                }, 5000);
            })
            .addCase(getJobDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getJobDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.jobDetails = action.payload;
            })
            .addCase(getJobDetails.rejected, (state, action) => {
                state.isLoading = false;
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Not Found') return;
                if (errorMessage === 'Unauthorized') errorMessage = t('unauthorized');
                Toast.Error({ content: errorMessage })
            })
            .addCase(unlockContactInfo.pending, (state) => {
                state.isUnlockLoading = true
            })
            .addCase(unlockContactInfo.fulfilled, (state, action) => {
                state.isUnlockLoading = false;
            })
            .addCase(unlockContactInfo.rejected, (state, action) => {
                state.isUnlockLoading = false;
                // TODO: 
                if (action?.error?.code !== 'FT-011') {
                    Toast.Error({ content: action.error?.message })
                }
            })
            .addCase(getApplicant.fulfilled, (state, action) => {
                state.applicantDetails = action.payload;
            })
            .addCase(getApplicant.rejected, (state, action) => {
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Unauthorized') errorMessage = t('unauthorized');
                if (action?.error?.code !== "FT-014") {
                    Toast.Error({ content: errorMessage })
                }
            })
            .addCase(getProducts.pending, (state) => {
                state.isProductListLoading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isProductListLoading = false;
                state.productList = action.payload
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isProductListLoading = false;
                Toast.Error({ content: action.error?.message })
            })
            .addCase(getJobTitles.fulfilled, (state, action) => {
                state.jobTitles = action.payload
            })
            .addCase(createJob.fulfilled, (state, action) => {
                state.matches = action.payload;
            })
            .addCase(createJob.rejected, (state, action) => {
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Unprocessable Entity') errorMessage = t('not_allowed');
                if (errorMessage === 'Job is rejected' || errorMessage === 'تم رفض الوظيفة') errorMessage = t('job_is_rejected');
                if (errorMessage === 'Invalid Request') errorMessage = t('invalid_request');

                Toast.Error({ content: errorMessage });
                const errorString = JSON.stringify({ report: `error in createJob`, error: errorMessage, message: errorMessage, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });
                captureMessage(errorString);
                if (errorMessage === t('not_allowed') || errorMessage === t('job_is_rejected') || errorMessage.includes('Unauthorized')) {
                    setTimeout(() => {
                        window.location.href = '/#/jobs';
                        window.location.reload();
                    }, 5000);
                }
            })
            .addCase(updateJob.rejected, (state, action) => {
                let errorMessage = action?.error?.message;
                if (errorMessage === 'Unprocessable Entity') errorMessage = t('not_allowed');
                if (errorMessage === 'Job is rejected' || errorMessage === 'تم رفض الوظيفة') errorMessage = t('job_is_rejected');
                if (errorMessage === 'Invalid Request') errorMessage = t('invalid_request');

                Toast.Error({ content: errorMessage });
                const errorString = JSON.stringify({ report: `error in updateJob`, error: errorMessage, message: errorMessage, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });
                captureMessage(errorString);
                if (errorMessage === t('not_allowed') || errorMessage === t('job_is_rejected') || errorMessage.includes('Unauthorized')) {
                    setTimeout(() => {
                        window.location.href = '/#/jobs';
                        window.location.reload();
                    }, 5000);
                }
            })
            .addCase(updateApplicantsNavParams, (state, action) => {
                state.applicantsNavParams = action.payload;
            })
            .addCase(getJobSeekersCount.fulfilled, (state, action) => {
                state.jobSeekersCount = action.payload?.count;
            })
            .addCase(passPaymentPrompt.fulfilled, (state, action) => {
                Toast.Success({ content: t('thank_you_for_your_answer') });
            })
            .addCase(passPaymentPrompt.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message });
            })
            .addCase(searchTypesenseLookup.rejected, (state, action) => {
                const errorString = JSON.stringify({ report: `error in searchTypesenseLookup`, error: action?.error, message: action?.error?.message, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });
                captureMessage(errorString);
            })
            .addCase(closeJob.fulfilled, (state, action) => {
                Toast.Success({ content: t('close_job_confirmation_message') });
            })
            .addCase(closeJob.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message });
            })
            .addCase(getRecommendedSkills.rejected, (state, action) => {
                Toast.Error({ content: action?.error?.message });
            })
    }
})

export const { handleClearApplicantsList, handleClearLastJobDraft } = appFullTimeJobListSlice.actions

export default appFullTimeJobListSlice.reducer
