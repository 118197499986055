import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import axios from "../../config/axios";
import Toast from "../../shared/toast/Toast";
import { ResponseResult } from "../../utils/interfaces/http/ResponseResult";
import { getPartnerInfo } from './partner';

const appKey = 'appPartnerUser'
const apiBaseUrl = 'ft/users'
const apiUpdateEmailUrl = `${apiBaseUrl}/email`
const apiUpdateMobileUrl = `${apiBaseUrl}/mobile`
const apiConfirmMobileUrl = `${apiUpdateMobileUrl}/confirm-mobile-update`

export const verifyPUEmail = createAsyncThunk(`${appKey}/verifyPUEmail`, async (body: any, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiUpdateEmailUrl}`, { ...body });
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

export const verifyPUMobile = createAsyncThunk(`${appKey}/verifyPUMobile`, async (body: any) => {
    const response = await axios.put<ResponseResult<any>>(`${apiUpdateMobileUrl}`, { ...body });
    return response.data.data;
})

export const confirmPUMobile = createAsyncThunk(`${appKey}/confirmPUMobile`, async (body: any, { dispatch }) => {
    const response = await axios.put<ResponseResult<any>>(`${apiConfirmMobileUrl}`, { ...body });
    if (response.status === 200) {
        dispatch(getPartnerInfo())
    }
    return response.data.data;
})

const initialState = {
    isLoading: true,
    data: null,
}

export const appPartnerUserSlice = createSlice({
    name: appKey,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(verifyPUEmail.fulfilled, (state, action) => {
                Toast.Success({ content: t('email_verification_sent') })
            })
            .addCase(verifyPUEmail.rejected, (state, action) => {
                Toast.Error({ content: action.error.message })
            })
            .addCase(confirmPUMobile.fulfilled, (state, action) => {
                Toast.Success({ content: t('mobile_number_updated') })
            })
            // .addCase(confirmPUMobile.rejected, (state, action) => {
            //     Toast.Error({ content: action.error.message })
            // })
    }
})

export default appPartnerUserSlice.reducer
