export enum GenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum JobPostGenderEnum {
    BOTH = "BOTH",
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum SaudiNationalityEnum {
    SAUDI = 'SAUDI',
    NONSAUDI = 'NON_SAUDI'
}

export enum GOSIEnum {
    GOSI = 'GOSI',
    NOGOSI = 'NO_GOSI'
}