import { CheckCircle, XCircle } from "react-feather";
import I18NText from "../i18nText/I18NText";
import SuccessToast from "./Success";
import ErrorToast from "./Error";

export const toastConfig = {
  position: "bottom-center",
};

export const ToastContent: React.FC<{
  type: any;
  content: any;
  title?: any;
}> = ({ content, title, type }) => (
  <>
    <div className="toastify-header d-none">
      <div className="title-wrapper">
        {type === "success" ? (
          <>
            <CheckCircle className="success" />
            <h6 className="toast-title success fw-bold">
              <I18NText text="success" />
            </h6>
          </>
        ) : (
          <>
            <XCircle className="danger" />
            <h6 className="toast-title fw-bold">
              <I18NText text="error" />
            </h6>
          </>
        )}
      </div>
    </div>
    <div className="toastify-body">
      {title && <div className="fw-bolder">{title}</div>}
      <span dangerouslySetInnerHTML={{ __html: content }}></span>
    </div>
  </>
);

const Toast = { Success: SuccessToast, Error: ErrorToast };

export default Toast;
