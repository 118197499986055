import { captureMessage } from '@sentry/browser'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { API_TIMEOUT, FULLTIME_PARTNER_ID_KEY, FULLTIME_PARTNER_TOKEN_KEY } from '../utils/constants/constants'
import { logoutUser } from '../utils/helpers/utils'

const customAxiosApi: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-type': 'Application/json',
        'accept-language': localStorage.getItem('i18nextLng')?.includes('en') ? 'en' : 'ar',
        'fulltime-partner-id': localStorage.getItem(FULLTIME_PARTNER_ID_KEY),
        'app-source': 'PWA'
    },
    withCredentials: false,
})

customAxiosApi.interceptors.request.use(
    (config: any) => {
        if (!config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(FULLTIME_PARTNER_TOKEN_KEY)}`
        }
        if (!config.headers[FULLTIME_PARTNER_ID_KEY]) {
            config.headers[FULLTIME_PARTNER_ID_KEY] = localStorage.getItem(FULLTIME_PARTNER_ID_KEY)
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

interface RetryConfig extends AxiosRequestConfig {
    retry: number;
    retryDelay: number;
}

const globalConfig: RetryConfig = {
    retry: 1,
    retryDelay: 5000,
    timeout: API_TIMEOUT
};


customAxiosApi.interceptors.response.use((response) => response, (error) => {
    const { config } = error;

    if (!config || !config.retry) {
        return Promise.reject(error);
    }

    config.headers.Authorization = `Bearer ${localStorage.getItem(FULLTIME_PARTNER_TOKEN_KEY)}`;
    config.headers[FULLTIME_PARTNER_ID_KEY] = localStorage.getItem(FULLTIME_PARTNER_ID_KEY);

    config.retry -= 1;

    const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
            console.log("retry the request", config.url);
            resolve();
        }, config.retryDelay || 1000);
    });

    const httpStatusCode = error?.response?.status;
    const page = window.location.href;
    const errorString = JSON.stringify({ report: `Received ${httpStatusCode} from backend`, error: { ...error?.response?.data?.error }, partnerId: localStorage.getItem(FULLTIME_PARTNER_ID_KEY) });

    if (httpStatusCode === 401 && !page.includes('email-success')) {
        logoutUser();
    }

    if (httpStatusCode === 400) {
        captureMessage(errorString);
    }

    if (httpStatusCode === 403) {
        setTimeout(() => {
            captureMessage(errorString);
            logoutUser();
        }, 5000);
    }

    return delayRetryRequest.then(() => customAxiosApi(config));
});

export { customAxiosApi, globalConfig }

