export enum LookupsSearchIndexEnum {
    PROD_GEO = 'PROD_GEO',
    STG_GEO = 'STG_GEO',
    PROD_JOB_TITLES = 'PROD_JOB_TITLES',
    PROD_EXPERIENCE_JOB_TITLES = 'PROD_EXPERIENCE_JOB_TITLES',
    PROD_STANDARD_JOB_TITLES = 'PROD_STANDARD_JOB_TITLES',
    PROD_EXPERIENCE_COMPANIES = 'PROD_EXPERIENCE_COMPANIES',
    PROD_EDUCATION_INSTITUTIONS = 'PROD_EDUCATION_INSTITUTIONS',
    PROD_EDUCATION_FIELDS_OF_STUDY = 'PROD_EDUCATION_FIELDS_OF_STUDY',
    PROD_TRAINING_INSTITUTIONS = 'PROD_TRAINING_INSTITUTIONS',
    PROD_TRAINING_PROGRAMS = 'PROD_TRAINING_PROGRAMS',
    PROD_LANGUAGES = 'PROD_LANGUAGES',
    PROD_WORKER_SKILLS = 'PROD_WORKER_SKILLS',
    PROD_SUB_SECTORS = 'PROD_SUB_SECTORS',
}